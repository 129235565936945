import { useEffect, useRef, useState } from 'react';
import { Helmet } from 'react-helmet';

import { Container, Divider, Grid, Header, Icon, Image, Segment } from 'semantic-ui-react';

import binImg from '../images/cookingoils-bin.png';
import atmImg from '../images/atm.png';


export default function WasteOilPoints() {
  // we need to load the map dynamically to avoid leaflet loading in server
  const mapRef = useRef();
  const [mapLoaded, setMapLoaded] = useState(false);
  const WasteOilMap = mapRef.current;

  // this only runs on the client, so server won't import leaflet!
  useEffect(() => {
    mapRef.current = require('./WasteOilMap').default;
    setMapLoaded(true);
  }, []);

  const pageTitle = 'Σημεία συλλογής τηγανέλαιων';
  return (<>
    <Helmet title={pageTitle} />

    <Container text textAlign="center">
      <Header as="h1" className="colored atmgreen" content={pageTitle} />

      <Grid centered columns={3} verticalAlign="bottom">
        <Grid.Column only="tablet computer" width={7} textAlign="center" style={{ fontSize: '2rem' }}>
          <Image inline>
            <Icon name="circle" color="orange" size="massive" className="binorange" />
            <Image src={binImg} style={{ position: 'absolute', maxHeight: '100%', bottom: '-.5em', right: 0 }} />
          </Image>
        </Grid.Column>
        <Grid.Column only="mobile" width={8} textAlign="center">
          <Image inline>
            <Icon name="circle" color="orange" size="massive" className="binorange" />
            <Image src={binImg} style={{ position: 'absolute', maxHeight: '100%', bottom: '-.5em', right: 0 }} />
          </Image>
        </Grid.Column>
        <Grid.Column width={1} only="computer tablet" />
        <Grid.Column only="mobile" width={8} textAlign="center">
          <Image inline>
            <Icon name="circle" color="green" size="massive" className="atmgreen" />
            <Image src={atmImg} style={{ position: 'absolute', maxHeight: '100%', bottom: '-.5em', left: 0 }} />
          </Image>
        </Grid.Column>
        <Grid.Column only="tablet computer" width={7} textAlign="center" style={{ fontSize: '2rem' }}>
          <Image inline>
            <Icon name="circle" color="green" size="massive" className="atmgreen" />
            <Image src={atmImg} style={{ position: 'absolute', maxHeight: '100%', bottom: '-.5em', left: 0 }} />
          </Image>
        </Grid.Column>
      </Grid>

      <Divider section hidden />
    </Container>

    <Container>
      <Divider className="orange" />
    </Container>

    <Segment basic attached className="colored lightgreen">
      <Divider hidden />

      <Container text textAlign="center">
        <Header as="h3">
          Δείτε στον χάρτη όλα τα σημεία της Δυτικής Μακεδονίας στα οποία πραγματοποιείται συλλογή τηγανέλαιων και συμμετέχετε και εσείς στην ανακύκλωσή τους
        </Header>

        <Divider hidden />

        <Grid centered columns={2} stackable className="compact">
          <Grid.Column width={6} textAlign="center">
            <Icon name="circle" color="orange" size="big" className="binorange" />Πορτοκαλί κάδοι τηγανέλαιων
          </Grid.Column>
          <Grid.Column width={6} textAlign="center">
            <Icon name="circle" color="green" size="big" className="atmgreen" />ΑΤΜ τηγανέλαιων
          </Grid.Column>
        </Grid>

        <Divider hidden />
      </Container>

      { mapLoaded && <WasteOilMap /> }

      <Divider section hidden />
    </Segment>
  </>);
}
