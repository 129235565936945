import { useState, useRef, useEffect } from 'react';
import { useParams } from 'react-router';
import { Link } from 'react-router-dom';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { Container, Message, Segment, Button, Divider, Input, Form, Header } from 'semantic-ui-react';

import axios from 'axios';

import ContributorSearch from './ContributorSearch';
import useProfile from '../queries/useProfile';
import { useContributor } from '../queries/contributors';


function BarcodeAssignmentForm({ admin }) {
  const { code, contributorId } = useParams();

  const queryClient = useQueryClient();

  const [scanCode, setScanCode] = useState(code || '');

  const profile = useProfile();
  const { data: contributor } = useContributor(contributorId);

  const barcode = useQuery(['barcode', { scanCode }],
    () => axios.get(`/api/sheets/verify/`, { params: { id_code: scanCode } }).then(({data}) => data),
    { retry: false, enabled: false }
  );

  // fetch immediately (just once) if code is present
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => code && barcode.refetch(), [code]);

  const assignMutation = useMutation(
    ({ id_code, contributor }) => axios.post('/api/sheets/assign/', { id_code, contributor }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('availableBarcodes');
        queryClient.invalidateQueries('registeredBarcodes');
        if (!admin) profile.refetch();
      },
    }
  );

  const handleFindBarcode = () => {
    if (!scanCode) return;
    assignMutation.reset();

    barcode.remove(); // need to remove first to make sure 404's return null
    barcode.refetch();
  };

  const barcodeRef = useRef();
  const handleDismiss = () => {
    assignMutation.reset();
    barcode.remove();
    setScanCode('');
    barcodeRef.current.focus();
  };

  const handleAssignToContributor = ({ contributor, email }) => {
    // we can send to mutate any other variables, it might not use them, but they are availabel afterwards :)
    assignMutation.mutate({ id_code: scanCode, contributor, email });
  };

  const handleAssign = () => {
    assignMutation.mutate({ id_code: scanCode, contributor: contributorId, email: contributor?.email });
  };

  return (
    <Segment padded={!code} basic={!!code}>
      <Form size="large" onSubmit={handleFindBarcode} error={barcode.error}>
        <Form.Field>
          { code ? <label>Κωδικός barcode:</label> : <label>{ admin ? 'Εισάγετε' : 'Πληκτρολόγησε' } τον κωδικό barcode του φύλλου:</label> }
          <Input ref={barcodeRef} icon="barcode" iconPosition="left" size="large" fluid
            value={scanCode}
            readOnly={!!code}
            onChange={(e, {value}) => setScanCode(value)}
            autoFocus
            action={ !code && {
              primary: true,
              disabled: !scanCode || !!code || assignMutation.isSuccess,
              content: 'Έλεγχος'
            }}
          />
        </Form.Field>

        <Message error content="Σφάλμα κατά τον εντοπισμό του κωδικού, παρακαλώ προσπαθήστε ξανά." onDismiss={!code && handleDismiss} />

      </Form>

      {
        barcode.data && !assignMutation.isSuccess && (
          (!barcode.data?.contributor) ?
            ((admin && !contributorId) ?
              <>
                <Message size="large" info content="Ο κωδικός είναι διαθέσιμος. Εισάγετε το email του χρήστη για τον οποίον θέλετε να ενεργοποιηθεί."/>
                <ContributorSearch onSelect={handleAssignToContributor} />
              </>
              : <>
                <Message positive size="large">
                  Ο κωδικός είναι διαθέσιμος. Θέλετε να τον ενεργοποιήσετε
                  { admin && <> για τον χρήστη <strong>{ contributor?.email }</strong></> }
                  ;
                </Message>
                <Button.Group floated="right">
                  <Button positive content="Ενεργοποίηση" onClick={handleAssign} />
                  { !code && <Button primary content="Νέα αναζήτηση" onClick={handleDismiss} /> }
                </Button.Group>

                <Divider clearing hidden fitted />
              </>) :
            <>
              <Message warning size="large">
                Ο κωδικός είναι ήδη ενεργοποιημένος {admin && <>για τον χρήστη <strong><Link to={`/admin/contributors/${barcode.data.contributor.id}`}>{barcode.data.contributor.email}</Link></strong></>}
              </Message>
              {!code && <Button floated="right" primary content="Νέα αναζήτηση" onClick={handleDismiss} />}
              <Divider clearing hidden fitted />
            </>
        )
      }

      { assignMutation.isError && 
        <Message error content="Σφάλμα κατά την ενεργοποίηση του κωδικού, παρακαλώ προσπαθήστε ξανά." />
      }

      { assignMutation.isSuccess && 
        <Message success size="large" onDismiss={!code && handleDismiss}>
          <Message.Content>
            Η ενεργοποίηση του κωδικού ολοκληρώθηκε επιτυχώς
            { admin && <> για τον χρήστη <strong>{assignMutation.variables.email}</strong></> }.
            <br />
            { !admin  && <span>Μπορείτε πλέον να τον χρησιμοποιείτε στα μπουκάλια προς παράδοση.</span> }
          </Message.Content>
        </Message>
      }

    </Segment>
  );
}

// only used for admin for now
function BarcodeAssignment() {
  return (<>
    <Container>
      <Header as="h1" textAlign="center" className="colored atmgreen">Ενεργοποίηση barcode</Header>
      <Divider className="orange" />
      <Divider hidden />
    </Container>

    <Container text>
      <BarcodeAssignmentForm admin />

      <Divider section hidden />
    </Container>
  </>);
}

BarcodeAssignment.Form = BarcodeAssignmentForm;

export default BarcodeAssignment;
