import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import { Container, Divider, Header, List } from 'semantic-ui-react';


function Terms() {
  const pageTitle = 'Όροι και προϋποθέσεις';
  return (<>
    <Helmet title={pageTitle} />
    <Container>
      <Header as="h1" textAlign="center" className="colored atmgreen" content={pageTitle} />
      <Divider className="orange" />
      <Divider hidden />
    </Container>

    <Container text>
      <p>Η ΔΙΑΔΥΜΑ ακολουθώντας τα ευρωπαϊκά πρότυπα εντάσσει στη φιλοσοφία της την ανταποδοτική διαχείριση αποβλήτων. Για το λόγο αυτό οι πολίτες της Δυτικής Μακεδονίας που συμμετέχουν στην εφαρμογή καλών πρακτικών διαχείρισης αποβλήτων θα επιβραβεύονται. Θα έχουν τη δυνατότητα συλλογής πόντων τους οποίους και θα εξαργυρώνουν σε συμβεβλημένα καταστήματα (δείτε περισσότερα εδώ: <a href="https://rewards.diadyma.gr">https://rewards.diadyma.gr</a>). Προϋπόθεση για την επιβράβευση αποτελεί η εγγραφή στις ψηφιακές υπηρεσίες της ΔΙΑΔΥΜΑ.</p>
      
      <p>Οι ψηφιακές υπηρεσίες της ΔΙΑΔΥΜΑ είναι ένας κοινός τόπος εγγραφής σε όλες τις εναλλακτικές, καλές πρακτικές διαχείρισης αποβλήτων που εφαρμόζει η ΔΙΑΔΥΜΑ, όπως η επαναχρησιμοποίηση και η ανακύκλωση τηγανέλαιων. Μετά την εγγραφή, θα συνδέεστε με το e-mail και το συνθηματικό σας και θα απολαμβάνετε μοναδικά προνόμια επιβράβευσης.</p>

      <Header as="h3">Ανακύκλωση τηγανέλαιων</Header>

      <p>Ο κάθε πολίτης θα μπορεί να συμμετέχει στη χωριστή συλλογή τηγανέλαιων με δύο (2) τρόπους.</p>
      
      <List ordered className="decimal">
        <List.Item>Χρησιμοποιώντας τον πορτοκαλί κάδο τηγανέλαιων
          <List bulleted>
            <List.Item>Αφήνουμε το λάδι να κρυώσει </List.Item>
            <List.Item>Βεβαιωνόμαστε ότι το πλαστικό μπουκάλι (χωρητικότητας 0.5, 1, 1.5 ή 2 λίτρων) που θα χρησιμοποιήσουμε είναι στεγνό (χωρίς νερό, ή κάποιο άλλο υγρό). Αν προηγουμένως είχε λάδι δεν υπάρχει πρόβλημα.</List.Item>
            <List.Item>Χρησιμοποιούμε ένα χωνί για να περάσουμε το λάδι στο πλαστικό μπουκάλι. </List.Item>
            <List.Item>Τοποθετούμε το μπουκάλι όπως είναι μέσα στον πορτοκαλί κάδο, χωρίς να το αδειάσουμε και χωρίς σακούλα.</List.Item>
          </List>
        </List.Item>
        <List.Item>Χρησιμοποιώντας το ΑΤΜ ανακύκλωσης τηγανέλαιων
          <List bulleted>
            <List.Item>Αφήνουμε το λάδι να κρυώσει </List.Item>
            <List.Item>Χρησιμοποιούμε ένα χωνί για να περάσουμε το λάδι στο ειδικό μπουκάλι των ΑΤΜ. </List.Item>
            <List.Item>Κλείνουμε καλά το καπάκι </List.Item>
            <List.Item>Μεταφέρουμε το μπουκάλι στο ΑΤΜ και το εισάγουμε στη θυρίδα συλλογής που βρίσκεται στο επάνω μέρος του μηχανήματος (χωρίς σακούλα)</List.Item>
            <List.Item>Περιμένουμε να μας δώσει το ΑΤΜ από την κάτω θυρίδα το νέο, καθαρό ειδικό μπουκάλι για το επόμενο γέμισμα.</List.Item>
          </List>
        </List.Item>
      </List>

      <strong>Τα αποδεκτά τηγανέλαια είναι όλα τα λάδια που χρησιμοποιούμε στο σπίτι μας για μαγείρεμα ή τηγάνισμα όπως:</strong>

      <List bulleted>
        <List.Item>Ελαιόλαδο</List.Item>
        <List.Item>Σπορέλαια (ηλιέλαιο, σογιέλαιο, αραβοσιτέλαιο, βαμβακέλαιο, φοινικέλαιο)</List.Item>
        <List.Item>Πυρηνέλαιο</List.Item>
        <List.Item>Παλιά ταγκισμένα λάδια</List.Item>
      </List>

      <strong>Προσοχή, δεν μπορούμε να τοποθετούμε τα εξής λάδια:</strong>
      <List bulleted>
        <List.Item>Λάδια που περιέχουν σάλτσες, νερό ή υπολείμματα τροφών (όπως από σαλάτες, φαγητά κλπ.)</List.Item>
        <List.Item>Ορυκτέλαια - Λάδια αυτοκινήτου</List.Item>
      </List>

      <strong>Πόντοι που κερδίζονται από την ανακύκλωση τηγανέλαιων:</strong>
      <List bulleted>
        <List.Item>Για κάθε 500 ml κερδίζονται 50 πόντοι.</List.Item>
        <List.Item>Για κάθε 1000 ml  κερδίζονται 100 πόντοι.</List.Item>
        <List.Item>Για κάθε 1500 ml κερδίζονται 150 πόντοι.</List.Item>
        <List.Item>Για κάθε 2000 ml κερδίζονται 200 πόντοι.</List.Item>
      </List>

      <p>Για να περαστούν οι πόντοι στους λογαριασμούς των εγγεγραμμένων χρηστών, θα πρέπει πρώτα οι πολίτες να προμηθευτούν τα ειδικά αυτοκόλλητα barcode τα οποία και θα κολλήσουν πάνω στα μπουκάλια με τα τηγανέλαια (για περισσότερες πληροφορίες δείτε τις <Link to="/questions">συχνές ερωτήσεις</Link>).</p>

      <Divider hidden />
    </Container>
  </>);
}

export default Terms;
