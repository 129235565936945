import { Route, useRouteMatch } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import { Container, Header, Divider } from 'semantic-ui-react';

import { BarcodesRegistered } from './BarcodesTable';
import BarcodeAssignment from './BarcodeAssignment';



export default function Barcodes({ admin }) {
  const match = useRouteMatch();

  const pageTitle = 'Ενεργοποίηση barcode';
  return (<>
    { !admin && 
      <Container>
        <Helmet title={pageTitle} />
        <Header as="h1" textAlign="center" className="colored atmgreen" content={pageTitle} />
        <Divider className="orange" />
        <Divider hidden />
      </Container>
    }

    <Container text={!admin}>
      <BarcodeAssignment.Form admin={admin} />

      <Route path={`${match.path}/:pageKey(page)?/:page(\\d+)?`}>
        <BarcodesRegistered admin={admin} />
      </Route>

      <Divider section hidden />

    </Container>
  </>);
}
