import { Grid, Header, Statistic } from 'semantic-ui-react';

import useProfile from '../queries/useProfile';


function PointsBalance() {
  const profile = useProfile();
  const contributor = profile.data?.contributor;

  return (
    <Grid padded divided stackable columns={2}>
      <Grid.Column textAlign="center">
        <Header as="h2">Έχεις συγκεντρώσει</Header>
        <Statistic>
          <Statistic.Value>
             {contributor?.points_collected}
          </Statistic.Value>
          <Statistic.Label>Πόντους</Statistic.Label>
          <Statistic.Label>από επιστροφή τηγανέλαιων</Statistic.Label>
        </Statistic>
      </Grid.Column>
      <Grid.Column textAlign="center">
        <Header as="h2">Έχεις διαθέσιμους</Header>
        <Statistic>
          <Statistic.Value>
            {contributor?.points_balance}
          </Statistic.Value>
          <Statistic.Label>Πόντους</Statistic.Label>
          <Statistic.Label>για εξαργύρωση</Statistic.Label>
        </Statistic>
      </Grid.Column>
    </Grid>
  );
}

export default PointsBalance;
