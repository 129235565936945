import { Cell, Legend, Pie, PieChart, Tooltip } from 'recharts';


export default function ContributorsPieChart({ activated, inactive, joined, layout='centric', ...props }) {
  return (
    <PieChart {...props}>
      <Pie dataKey="value"
        data={[
          { name: 'Με barcode', value: activated },
          { name: 'Χωρίς barcode', value: inactive },
        ]}
      >
        <Cell fill="green" />{/* activated */}
        <Cell fill="brown" />{/* inactive */}
      </Pie>
      <Tooltip />
      <Legend layout={layout}
        payload={[
          { type: 'rect', color: 'green', value: <>Με barcode: <strong>{activated}</strong></> },
          { type: 'rect', color: 'brown', value: <>Χωρίς barcode: <strong>{inactive}</strong></> },
          { type: 'rect', color: 'transparent', value: <span style={{ color: 'black' }}>Σύνολο: <strong>{joined}</strong></span> },
        ]}
      />
    </PieChart>
  );
}
