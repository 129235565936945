import { useMemo, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { useQuery } from 'react-query';
import { Helmet } from 'react-helmet';

import { Container, Divider, Grid, Header, Label, List, Segment, Sticky, Message, Checkbox } from 'semantic-ui-react';
import { Cell, Tooltip, ResponsiveContainer, XAxis, BarChart, Bar, YAxis } from 'recharts';

import DailyStats from './DailyStats';
import ContributorsPieChart from './dashboard/ContributorsPieChart';

import dayjs from 'dayjs';
import 'dayjs/locale/el';
import axios from 'axios';

import { DateFilter } from '@zerowaste/components';

dayjs.locale('el');

const barChartColors = [
  '#218380',
  '#d81159',
  '#fbb13c',
  '#73d2de',
  '#255c99',
  '#b3001b',
  '#262626',
  '#7ea3cc',
  '#ccad8f',
  '#8f2d56',
  '#ff595e',
  '#ffca3a',
  '#8ac926',
  '#1982c4',
  '#6a4c93',
];

function WasteOilDashboard() {
  const [dateFrom, setDateFrom] = useState(null);
  const [dateTo, setDateTo] = useState(null);

  const dateParams = useMemo(() => {
    return {
      date_after: dateFrom ? dayjs(dateFrom).format('YYYY-MM-DD') : undefined,
      date_before: dateTo ? dayjs(dateTo).format('YYYY-MM-DD') : undefined,
    };
  }, [dateFrom, dateTo]);

  const { data: contributorCounts } = useQuery(['contributors_stats', dateParams], 
  () => axios.get('/api/contributors/counts/', { params: { ...dateParams } }).then(({data}) => data));

  const { data: dailyContributors } = useQuery(
    ['daily_activations', dateParams],
    () => axios.get('/api/contributors/daily/', { params: { ...dateParams } }).then(({data}) =>
      // convert to milliseconds
      data.map((d) => ({...d, 'date_joined': dayjs(d.date_joined).valueOf()}))
    )
  );

  const { data: topContributors } = useQuery(
    ['top_participants', dateParams],
    () => axios.get('/api/contributors/active/', { params: {...dateParams } }).then(({data}) => data)
  );

  const { data: containerCollections } = useQuery(
    ['container_collections', dateParams],
    () => axios.get('/api/collections/containers/', { params: {...dateParams } }).then(({data}) => data)
  );

  const { data: collectionMunicipalities } = useQuery(
    ['municipality_collections', dateParams],
    () => axios
      .get('/api/collections/municipalities/', { params: {...dateParams } })
      .then(({data}) => data.map((m) => ({...m, collected_amount: m.collected_amount / 1000 })))
  );

  const { data: dailyCollections } = useQuery(
    ['weekly_collections', dateParams],
    () => axios.get('/api/collections/daily/', { params: { ...dateParams } }).then(({data}) =>
      // convert to milliseconds and liters
      data.map((d) => ({ collected_amount: d.collected_amount / 1000, date_collected: dayjs(d.date_collected).valueOf()}))
    )
  );

  const {data: contributorMunicipalities} = useQuery(
    ['municipality_contributors', dateParams],
    () => axios
      .get('/api/contributors/municipalities/', {params: {...dateParams} })
      .then(({data}) => data.map((m) => ({...m, contributors_total: m.contributors_joined + m.contributors_activated }))
    )
  );

  const [showOnlyActivated, setShowOnlyActivated] = useState(false);

  const contextRef = useRef();
  const pageTitle = 'Επισκόπηση στατιστικών χρήσης';
  return (
    <Container>
      <Helmet title={pageTitle} />
      <Header as="h1" textAlign="center" className="colored atmgreen" content={pageTitle} />
      <Divider className="orange" />
      <Divider hidden />

      <Sticky context={contextRef}>
        <DateFilter
          dateFrom={dateFrom}
          setDateFrom={setDateFrom}
          dateTo={dateTo}
          setDateTo={setDateTo}
        />
      </Sticky>

      <Divider hidden />

      <div ref={contextRef}>
      <Grid columns={1} stretched>
        <Grid.Column>
          <Segment basic color="green">
            <Header size="large" textAlign="center" content="Χρήστες" />
            <Grid columns={1} stretched>
              <Grid.Column>
                <Segment raised>
                  <Header content="Εγγραφές χρηστών" />
                  <DailyStats data={dailyContributors} dateFrom={dateFrom} dateTo={dateTo} dateDataField="date_joined"
                    lines={[
                      { dataKey: 'joined', name: 'Εγγραφές', stroke: 'brown' },
                      { dataKey: 'activated', name: 'Παραλαβές barcode', stroke: 'green' },
                    ]}
                    allowDecimals={false}
                  />
                </Segment>
              </Grid.Column>

              <Grid.Column mobile={16} tablet={8} computer={8}>
                <Segment raised>
                  <Header content="Εγγεγραμμένοι χρήστες" />
                  { contributorCounts?.joined === 0 ?
                    <Message content="Δεν υπάρχουν εγγραφές χρηστών." />
                    :
                    <ResponsiveContainer height={300}>
                      <ContributorsPieChart width={400} height={300} {...contributorCounts} />
                    </ResponsiveContainer>
                  }
                </Segment>
              </Grid.Column>

              <Grid.Column mobile={16} tablet={8} computer={8}>
                <Segment raised>
                  <Header content="Οι 10 πιο ενεργοί χρήστες" />
                  <List size="large" divided>
                    { topContributors?.map((u, index) =>
                        <List.Item key={u.email}>
                          <Label content={index + 1} circular color="blue" style={{ marginRight: '1em', width: '2.5em', textAlign: 'center' }} />
                          <Link to={`/admin/contributors/${u.id}`}>{u.email}</Link>
                          <List.Content floated="right">
                            <Label pointing="left" size="large" color="teal" content={`${u.collected_amount / 1000} λίτρα`} />
                          </List.Content>
                        </List.Item>
                      )
                    }
                  </List>
                  { topContributors?.length === 0 &&
                    <Message content="Δεν υπάρχουν καταγεγραμμένες συλλογές τηγανέλαιων." />
                  }
                </Segment>
              </Grid.Column>

            </Grid>
          </Segment>
        </Grid.Column>

        <Grid.Column>
          <Segment basic color="blue">
            <Header size="large" textAlign="center" content="Συλλογή τηγανέλαιων" />
            <Segment raised>
              <DailyStats data={dailyCollections} dateFrom={dateFrom} dateTo={dateTo} dateDataField="date_collected"
                lines={[
                  { dataKey: 'collected_amount', name: 'Συλλεχθέντα λίτρα', stroke: 'royalblue' },
                ]}
              />
            </Segment>
          </Segment>
        </Grid.Column>

        <Grid.Column>
          <Segment basic color="red">
            <Header size="large" textAlign="center" content="Μπουκάλια" />
            <Segment raised>
              <ResponsiveContainer height={300}>
                <BarChart data={containerCollections} layout="vertical" >
                  <XAxis type="number" allowDecimals={false} />
                  <YAxis type="category" dataKey="label" tickLine={false} width={100} />
                  <Tooltip cursor={false} />
                  <Bar name="Συλλεχθέντα μπουκάλια" dataKey="collections_count">
                    { containerCollections?.map((c, index) => (
                        <Cell key={`cell-${index}`} fill={barChartColors[index  % barChartColors.length]} />
                      ))
                    }
                  </Bar>
                </BarChart>
              </ResponsiveContainer>
            </Segment>
          </Segment>
        </Grid.Column>

        <Grid.Column>
          <Segment basic color="yellow">
            <Header size="large" textAlign="center" content="Δήμοι" />
            <Segment raised>
              <ResponsiveContainer height={600}>
                <BarChart data={collectionMunicipalities} layout="vertical">
                  <XAxis type="number" allowDecimals={false}/>
                  <YAxis type="category" dataKey="name" tickLine={false} width={150} />
                  <Tooltip cursor={false} />
                  <Bar name="Συλλεχθέντα λίτρα" dataKey="collected_amount">
                    { collectionMunicipalities?.map((c, index) => (
                        <Cell key={`cell-${index}`} fill={barChartColors[(barChartColors.length - index - 1) % barChartColors.length]} />
                      ))
                    }
                  </Bar>
                </BarChart>
              </ResponsiveContainer>
            </Segment>
          </Segment>
        </Grid.Column>

        <Grid.Column>
          <Segment basic color="yellow">
            <Header size="large" textAlign="center" content="Εγγεγραμμένοι χρήστες ανά Δήμο" />
            <Segment raised>
              <ResponsiveContainer height={600}>
                <BarChart data={contributorMunicipalities} layout="vertical">
                  <XAxis type="number" allowDecimals={false} />
                  <YAxis type="category" dataKey="name" tickLine={false} width={150} />
                  <Tooltip cursor={false} content={<ContributorTooltip />} />
                  <Bar name="Νέες εγγραφές" dataKey={showOnlyActivated ? 'contributors_activated' : 'contributors_total'}>
                    { contributorMunicipalities?.map((c, index) => (
                        <Cell key={`cell-${index}`} fill={barChartColors[(barChartColors.length - index - 1) % barChartColors.length]} />
                      ))
                    }
                  </Bar>
                </BarChart>
              </ResponsiveContainer>
            </Segment>
            <Checkbox style={{float: 'right'}} toggle label="Μόνο χρήστες με barcode"
              checked={showOnlyActivated} onChange={(e, { checked }) => setShowOnlyActivated(checked)}
            />
          </Segment>
        </Grid.Column>

      </Grid>
      </div>

      <Divider section hidden />
    </Container>
  );
}

function ContributorTooltip({ active, payload, label }) {
  if (active && payload && payload.length) {
    // all municipality data is in first payload object, in its `payload` attribute
    const { contributors_activated: activated, contributors_joined: inactive, contributors_total: joined } = payload[0].payload;
    return (
      <Segment attached>
        {/* <Header as="h5" content={label} textAlign="center" /> */}
        {label}
        { joined > 0 ?
            <ContributorsPieChart height={200} width={200}
              layout="vertical"
              activated={activated} inactive={inactive} joined={joined}
            />
          : <>: 0</>
        }
      </Segment>
    );
  }

  return null;
}

export default WasteOilDashboard;
