import { Link } from 'react-router-dom';
import { Header, Grid, Image, Icon, Segment, Container, List } from 'semantic-ui-react';

import lesswasteImg from '../images/LesswasteII.png';
import diadymaImg from '../images/DIADYMA_L_WHITE.png';
import symbiolabsImg from '../images/SymbiolabsLogo.png';

import qs from 'qs';


function WasteOilFooter() {
  return (
    <>
      <Segment basic className="footer-menu" inverted>
        <Grid doubling stackable container columns={4} style={{ marginTop: 0, marginBottom: 0}}>
          <Grid.Column>
            <Image href="https://diadyma.gr" src={diadymaImg} />
            <p />
            <Image size="medium" rounded bordered href="https://ii.less-waste.eu/" src={lesswasteImg} />
          </Grid.Column>
            
          <Grid.Column>
            <Header className="footer-header" inverted>Σχεδιασμός και ανάπτυξη</Header>
            <Image size="small" href="https://symbiolabs.gr" src={symbiolabsImg} />
          </Grid.Column>

          <Grid.Column>
            <Header className="footer-header" inverted>Επικοινωνία</Header>
            <List items={[
              '6ο χλμ. Κοζάνης - Πτολεμαΐδας',
              'Κόμβος Εγνατίας',
              'Τ.Κ. 50 100 - Τ.Θ. 155',
            ]} />

            <List items={[
              {
                key: 'tel',
                icon: <Icon className="t green-icon" />,
                content: '24610 45531 - 24610 45533',
              },
              {
                key: 'fax',
                icon: <Icon className="f green-icon" />,
                content: '24610 45532',
              },
            ]} />
            
            <List items={[{
              key: 'email',
              icon: <Icon name="mail outline" className="green-icon" />,
              content: 'zerowaste@diadyma.gr',
            }]} />

          </Grid.Column>

          <Grid.Column>
            <Header className="footer-header" inverted>Site map</Header>
            <List inverted className="footer-links" items={[
              { key: 'home', content: <Link to="/" className="footer-link">Αρχικη</Link> },
              { key: 'points', content: <Link to="/points" className="footer-link">Σημεια συλλογης</Link> },
              { key: 'questions', content: <Link to="/questions" className="footer-link">Συχνες ερωτησεις</Link> },
              { key: 'news', content: <Link to="/news" className="footer-link">Νεα</Link> },
            ]} />

            <List inverted className="footer-links" items={[
              { key: 'admin', content: <Link to={{ pathname: '/admin', search: `?${qs.stringify({ cancelTo: window.location.pathname })}` }} className="footer-link">Περιβαλλον διαχειρισης</Link> },
            ]} />

            <List inverted className="footer-links" items={[
              { key: 'guide', content: <a href={`${process.env.PUBLIC_URL}/CookingOils_User_Manual.pdf`} target="_blank" rel="noreferrer" className="footer-link">Οδηγιες χρησης</a> },
              { key: 'terms', content: <Link to="/terms" className="footer-link">Οροι και προϋποθεσεις</Link> },
            ]} />

          </Grid.Column>

        </Grid>

        <Container>
          <Header className="footer-header" inverted>&nbsp;</Header>

          <p>The Project co-funded by the European Union and national funds of the participating countries.<br />
            &copy; 2019. All Rights Reserved.
          </p>
          
          <p>DISCLAIMER<br/>
            This webpage has been produced with the financial assistance of the European Union.
            The contents of the webpage are the sole responsibility of Waste Management of Western Macedonia DIADYMA SA
            and can in no way be taken to reflect the views of the European Union, the participating countries and the Managing Authority.
          </p>
        </Container>

      </Segment>
    </>
  );
}

export default WasteOilFooter;
