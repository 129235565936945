import { Switch, Route, NavLink, useParams, useRouteMatch, useLocation, matchPath } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import { Menu, Container, Header, Divider, Grid, Segment, Form } from 'semantic-ui-react';

import Barcodes from './Barcodes';
import ContributorsList from './ContributorsList';

import { useContributor } from '../queries/contributors';


function ContributorDetails({ parentPageTitle }) {
  const match = useRouteMatch();
  const { contributorId } = useParams();

  const contributor = useContributor(contributorId);
  const contributorDisplayName = contributor.data && (contributor.data.full_name || 'Άγνωστο όνομα');

  const collectedAmount = (contributor.data?.collected_amount / 1000) || 0 ;

  return (<>
    <Menu secondary pointing size="huge">
      <Menu.Item name="details" as={NavLink} to={match.url} exact>
        Στοιχεία χρήστη
      </Menu.Item>
      <Menu.Item name="offers" as={NavLink} to={`${match.url}/barcodes`}>
        Ενεργοποίηση Barcode
      </Menu.Item>
    </Menu>

    <Switch>
      <Route path={`${match.path}/barcodes`}>
        <Barcodes admin />
      </Route>

      <Route>
        <Segment secondary placeholder={contributor.isLoading} loading={contributor.isLoading}>
          <Header as="h3" dividing content={contributorDisplayName} />
          <Form>
            <Form.Group widths={2}>
              <Form.Input readOnly
                label="Όνομα"
                value={contributor.data?.first_name || ''}
              />
              <Form.Input readOnly
                label="Επώνυμο"
                value={contributor.data?.last_name || ''}
              />
            </Form.Group>

            <Form.Group widths={2}>
              <Form.Input
                readOnly
                label="Τηλέφωνο"
                value={contributor.data?.telephone || ''}
              />
              <Form.Input readOnly
                label="E-mail"
                value={contributor.data?.email || ''}
              />
            </Form.Group>
            <Form.Input readOnly
              label="Δήμος"
              value={contributor.data?.municipality_name || ''}
            />

            <Divider />
            <Form.Group widths={2}>
              <Form.Input readOnly
                label="Συλλεχθείσα ποσότητα τηγανέλαιων"
                value={`${collectedAmount.toLocaleString('el')} λίτρα`}
              />
              <Form.Input readOnly
                label="Συλλεχθέντες πόντοι"
                value={contributor.data?.points_collected ?? ''}
              />
            </Form.Group>
          </Form>
        </Segment>
      </Route>
    </Switch>

  </>);
}

export default function ContributorsAdmin() {
  const match = useRouteMatch();
  const location = useLocation();

  const barcodesActive = !!matchPath(location.pathname, { path: `${match.path}/:contributorId/barcodes`});

  const pageTitle = 'Διαχείριση χρηστών';
  return (<>
    <Container>
      <Helmet title={pageTitle} />
      <Header as="h1" textAlign="center" className="colored atmgreen" content={pageTitle} />
      <Divider className="orange" />
      <Divider hidden />

      <Grid centered>
        <Grid.Row>
          <Grid.Column width={4}>
            <ContributorsList parentPageTitle={pageTitle} parentMatch={match} pathSuffix={barcodesActive ? '/barcodes' : ''} as="div" listHeight="50vh" style={{ height: '50vh' }} />
          </Grid.Column>

          <Grid.Column width={12}>

            <Route path={`${match.path}/:contributorId`}>
              <ContributorDetails />
            </Route>

          </Grid.Column>
        </Grid.Row>
      </Grid>

      <Divider section hidden />
    </Container>
  </>);
}
