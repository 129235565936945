import { useCallback } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { useQuery } from 'react-query';

import { Container, Divider, Grid, Header, List, Image, Icon, Segment } from 'semantic-ui-react';

import bottlesImg from '../images/cookingoils-bottles.png';
import binImg from '../images/cookingoils-bin.png';
import atmBottleImg from '../images/atm-bottle.png';
import atmImg from '../images/atm.png';
import cookingOilsOnlyImg from '../images/cookingoils-only.png';

import useProfile from '../queries/useProfile';

import axios from 'axios';


function WasteOilIntro({ onRegister }) {
  const history = useHistory();
  const profile = useProfile();

  const isStaff = profile.data && (!profile.data.contributor );

  const handleRegisterButton = useCallback(() => {
    if (profile.data) {
      // logged in
       history.push('/profile');
    } else {
      onRegister();
    }
  }, [profile, history, onRegister]);

  const actionHeaders = {
    register: <Header block size="huge" className="action-header blue"
        as={ isStaff ? Link : 'a' }
        to={ isStaff ? '/admin' : undefined }
        onClick={ isStaff ? undefined : handleRegisterButton}
      >
      <Icon name="chevron right" />Κανε εγγραφη
    </Header>,
    recycle: <Header block size="huge" className="action-header green"
      as={Link} to="/recycle"
    >
      <Icon name="chevron right" />Κερδισε ποντους
    </Header>,
    rewards: <Header block size="huge" className="action-header orange"
      as="a" href="https://rewards.diadyma.gr"
    >
      <Icon name="chevron right" />Εξαργυρωσε ποντους
    </Header>,
  };

  const { data: containers } = useQuery('containers',
    () => axios.get('/api/containers/').then(({ data }) => data));

  return (<>
    <Helmet title="Αρχική" />
    <Container>
      <Grid stackable className="compact">
        <Grid.Column width={4} verticalAlign="middle" only="computer tablet">
          <Image src={bottlesImg}/>
        </Grid.Column>
        <Grid.Column width={2} verticalAlign="middle" only="computer tablet">
          <Image src={binImg} />
        </Grid.Column>
        <Grid.Column width={6} verticalAlign="middle">
          <Header as="h3" size="huge" className="atmgreen" textAlign="center">
            Ανακυκλώνουμε το μαγειρικό λάδι και συνεισφέρουμε στην προσπάθεια για ένα καθαρό και βιώσιμο περιβάλλον!
          </Header>
        </Grid.Column>
        <Grid.Column width={1} verticalAlign="bottom" only="computer tablet">
          <Image src={atmBottleImg} />
        </Grid.Column>
        <Grid.Column width={3} verticalAlign="bottom" only="computer tablet">
          <Image src={atmImg} size="medium" />
        </Grid.Column>
      </Grid>

      <Divider section className="orange" />
      <Divider hidden />

      <Grid className="compact" stackable stretched columns={3}>
        {/* always first */}
        <Grid.Column>{ actionHeaders.register }</Grid.Column>
        {/* show then in same line only in computer and tablet */}
        <Grid.Column only="computer tablet">{ actionHeaders.recycle }</Grid.Column>
        <Grid.Column only="computer tablet">{ actionHeaders.rewards }</Grid.Column>

        <Grid.Column>
          <Segment basic size="large">
            <p>Κάνε την εγγραφή σου τώρα και κέρδισε από την ανταποδοτική ανακύκλωση τηγανέλαιων.</p>
            <p>Δεν χρειάζεται αν είσαι ήδη εγγεγραμμένος στις ψηφιακές υπηρεσίες της ΔΙΑΔΥΜΑ</p>
          </Segment>
        </Grid.Column>

        <Grid.Column only="mobile">{ actionHeaders.recycle }</Grid.Column>
        <Grid.Column>
          <Segment basic size="large">
            <p>Χρησιμοποίησε το αυτοκόλλητο barcode και κέρδισε πόντους ανάλογα με το βάρος των τηγανέλαιων που έδωσες! (εφόσον έχεις εγγραφεί στις ψηφιακές υπηρεσίες της ΔΙΑΔΥΜΑ)</p>

            <List bulleted className="decorated green">
              {containers?.map(({ id, label, points }) =>
                <List.Item key={id}><span>Για κάθε μπουκάλι με τηγανέλαια<br/><strong>{label}</strong> κερδίζεις <strong>{points}</strong> πόντους</span></List.Item>
              )}
            </List>

          </Segment>
        </Grid.Column>

        <Grid.Column only="mobile">{ actionHeaders.rewards }</Grid.Column>
        <Grid.Column>
          <Segment basic size="large">
            <p>Οι πόντοι που κερδίζεις ανάλογα με την ποσότητα των τηγανέλαιων που ανακυκλώνεις μπορούν να εξαργυρωθούν σε μοναδικά προνόμια και δώρα, προσκομίζοντας πάντα το αντίστοιχο κουπόνι εξαργύρωσης!</p>
          </Segment>
        </Grid.Column>
      </Grid>
    </Container>

    <Segment padded="very" basic className="lightgreen">
      <Container text>
        <Header as="h3">Ανακύκλωση τηγανέλαιων</Header>
        <p>
          Η ΔΙΑΔΥΜΑ ΑΕ ήδη από το 2009 εφαρμόζει, στη Δυτική Μακεδονία, χωριστή συλλογή αποβλήτων με Διαλογή Στην Πηγή, 4 ρευμάτων (χαρτί, γυαλί, πλαστικό και μέταλλα). Έπειτα το 2016 ξεκίνησαν και τα πρώτα πιλοτικά προγράμματα Διαλογής στην Πηγή (ΔσΠ) για τα ρεύματα των βιοαποβλήτων (υπολείμματα φαγητού και οργανικά απόβλητα κουζίνας) και των τηγανέλαιων (μαγειρικό λάδι). Τα τηγανέλαια αποτελούν έναν ρύπο που αν καταλήξει στην αποχέτευση ή στα απορρίμματα προκαλεί σοβαρές περιβαλλοντικές επιπτώσεις. Αν όμως ανακυκλωθούν, μπορεί να παραχθεί βιοκαύσιμο το οποίο με τη σειρά του μπορεί να αντικαταστήσει ένα συμβατικό ρυπογόνο καύσιμο, όπως το πετρέλαιο θέρμανσης.
        </p>
        <p>
          Η ΔΙΑΔΥΜΑ ΑΕ ακολουθώντας τα ευρωπαϊκά πρότυπα εντάσσει στη φιλοσοφία της την ανταποδοτική διαχείριση αποβλήτων. Για το λόγο αυτό οι πολίτες της Δυτικής Μακεδονίας που συμμετέχουν στην ανακύκλωση τηγανέλαιων θα επιβραβεύονται. Θα έχουν τη δυνατότητα συλλογής πόντων τους οποίους και θα εξαργυρώνουν σε μοναδικά προνόμια και δώρα.
        </p>

        <Divider hidden section />

        <Header as="h3">Ποια είναι τα τηγανέλαια</Header>

        <p>Όλα τα λάδια που χρησιμοποιούμε στο σπίτι μας για μαγείρεμα ή τηγάνισμα όπως:</p>

        <List relaxed className="decorated green check icon">
          <List.Item><span>Ελαιόλαδο</span></List.Item>
          <List.Item><span>Σπορέλαια (ηλιέλαιο, σογιέλαιο, αραβοσιτέλαιο, βαμβακέλαιο, φοινικέλαιο)</span></List.Item>
          <List.Item><span>Πυρηνέλαιο</span></List.Item>
          <List.Item><span>Παλιά ταγκισμένα λάδια</span></List.Item>
        </List>

        <Divider hidden />

        <Header as="h3">Ποια λάδια δεν πρέπει να τοποθετούνται στα σημεία συλλογής τηγανέλαιων</Header>
        
        <List relaxed className="decorated red close icon">
          <List.Item><span>Λάδια που περιέχουν σάλτσες, νερό ή υπολείμματα τροφών (όπως από σαλάτες, φαγητά κλπ.)</span></List.Item>
          <List.Item><span>Ορυκτέλαια &amp; Λάδια αυτοκινήτου</span></List.Item>
        </List>

        <Divider section />

        <Header as="h3">Γνωρίζετε ότι:</Header>

        <List relaxed bulleted className="decorated orange">
          <List.Item><span>Τα τηγανέλαια είναι εξαιρετικά ρυπογόνα και ευθύνονται για πληθώρα προβλημάτων στο έδαφος, τα υδάτινα οικοσυστήματα, το σύστημα αποχετεύσεων (υπόνομοι) και τους βιολογικούς καθαρισμούς.</span></List.Item>
          <List.Item><span>Σύμφωνα με την Ευρωπαϊκή Ένωση (ΕΕ) θα πρέπει να συλλέγονται χωριστά από τα υπόλοιπα απόβλητα ενός νοικοκυριού και φέρουν τον κωδικό 20 01 25 Βρώσιμα έλαια και λίπη στον Ευρωπαϊκό Κατάλογο Αποβλήτων.</span></List.Item>
          <List.Item><span>Στην ΕΕ 4 εκ τόνοι τηγανέλαιων παράγονται κάθε χρόνο και μόνο το 1/7 αυτού το ποσού συλλέγεται.</span></List.Item>
          <List.Item><span>Όταν συλλεχθούν χωριστά και οδηγηθούν προς ανακύκλωση είναι χρήσιμα ως επί το πλείστον στην παραγωγή βιο-λιπαντικών, βιοκαυσίμων, ενέργειας, ζωοτροφών.</span></List.Item>
        </List>

        <Divider section />

      </Container>
    </Segment>

    <Container textAlign="center">
      <Divider section hidden />
      <Image centered size="medium" src={cookingOilsOnlyImg} />
      <Header size="huge">Ανακύκλωσε τηγανέλαια και κέρδισε!</Header>
    </Container>

    <Divider section hidden />
  </>);
}

export default WasteOilIntro;
