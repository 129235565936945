import { useState, useEffect } from 'react';
import { Link, NavLink, Route, Switch, useHistory, useParams, useRouteMatch, useLocation, matchPath } from 'react-router-dom';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { Helmet } from 'react-helmet';

import { Grid, Container, Loader, Message, Header, Button, List, Divider, Popup, Form, Label, Menu } from 'semantic-ui-react';

import axios from 'axios';
import qs from 'qs';

import { BarcodesRegistered, BarcodesAvailable } from './BarcodesTable';
import BarcodeAssignment from './BarcodeAssignment';


function BarcodeSheets() {
  const { pathname } = useLocation();
  const match = useRouteMatch();
  const { runId } = useParams();

  const { data: sheetRun } = useQuery(['sheet_run', { runId }],
    () => runId ?
        axios.get(`/api/sheet_runs/${runId}/`).then(({data}) => data)
        // ti kano thee moy!!!!
      : axios.get('/api/sheets/', { params: { print_run: 'none' }}).then(({data}) => ({
        size: data.count,
        // activated_count: data.filter((s) => !!s.contributor).length,
        // available_count: data.filter((s) => !s.contributor).length,
      }))
  );

  const [selectedSheets, setSelectedSheets] = useState(() => []);
  // empty selection when switching sheet run pages
  useEffect(() => setSelectedSheets([]), [runId]);

  const printUrl = sheetRun?.size && '/api/sheets/print_barcodes/?' +
    qs.stringify({
      id: selectedSheets.length ? selectedSheets : undefined,
      print_run: selectedSheets.length ? undefined : (runId || 'none'),
    }, {
      arrayFormat: 'repeat'
    });

  return (
    <>
      <Menu compact secondary pointing size="huge">
        <Menu.Item content="Ενεργοποιημένοι κωδικοί" as={Link} to={match.url}
          active={!matchPath(pathname, { path: `${match.path}/available` })}
        />
        <Menu.Item content="Διαθέσιμοι κωδικοί" as={NavLink} to={`${match.url}/available`}/>
      </Menu>

      <Menu secondary floated="right">
        <Menu.Item>
          <Button as="a" href={printUrl} disabled={!printUrl} icon="print" content="Εκτύπωση κωδικών ομάδας" />
        </Menu.Item>
      </Menu>

      <Divider fitted hidden clearing />

      <Switch>
        <Route path={`${match.path}/available/:pageKey(page)?/:page(\\d+)?`}>
          <BarcodesAvailable />
        </Route>

        <Route path={`${match.path}/:pageKey(page)?/:page(\\d+)?`} exact>
          <BarcodesRegistered admin />
        </Route>
      </Switch>

    </>
  );
}


// eslint-disable-next-line no-unused-vars
function BarcodeSheetRunPrintButton({ runId, runSize }) {
  const printUrl = runSize && '/api/sheets/print_barcodes/?' +
    qs.stringify({ print_run: runId || 'none' });

  return (
    <Button as="a" href={printUrl} disabled={!printUrl} icon="print" content="Εκτύπωση" />
  );
}


function BarcodeSheetRuns() {
  const queryClient = useQueryClient();

  const match = useRouteMatch();
  const history = useHistory();

  // check if we have available tab open
  const { pathname } = useLocation();
  const availableActive = !!matchPath(pathname, {
    path: [`${match.path}/available`, `${match.path}/:runId/available`]
  });
  const pathSuffix = availableActive ? '/available' : '';

  const runs = useQuery('sheet_runs', () =>
    axios.get('/api/sheet_runs/', { params: { ordering: '-created_at' } }).then(({data}) => data)
  );

  const ungrouped = useQuery('ungroupedBarcodes',
    () => axios.get('/api/sheets/', { params: { print_run: 'none' }}),
    { select: ({data}) => data }
  );

  const [newRunSize, setNewRunSize] = useState(10);
  const handleChangeRunSize = (e, { value }) => {
    if (value >= 0) setNewRunSize(value);
  };

  const [newRunOpen, setNewRunOpen] = useState(false);
  const newRunMutation = useMutation(() => axios.post('/api/sheet_runs/', { size: newRunSize }), {
    onSuccess: ({data: { id }}) => {
      queryClient.invalidateQueries('sheet_runs');
      setNewRunOpen(false);
      history.push(`${match.url}/${id}`);
    }
  });

  return (
    <Grid centered>
      <Grid.Row>
        <Grid.Column width={4}>
          { runs.isLoading && <Loader active /> }
          { runs.error && <Message error content="Κάτι πήγε στραβά!" /> }

          <Menu secondary pointing size="huge">
            <Menu.Item active content="Ομάδες φύλλων" />
          </Menu>

          <List selection divided className="narrow-list" size="large" style={{ maxHeight: '40vh', overflow: 'auto' }}>
            <List.Item as={Link} to={`${match.url}${pathSuffix}`}
              active={!matchPath(pathname, `${match.path}/:runId(\\d+)`)}
            >
              <List.Content floated="right">
                <Label color="teal" content={ungrouped.data?.count} title={`${ungrouped.data?.count} κωδικ${ungrouped.data?.count === 1 ? 'ός' : 'οί'}`} />
              </List.Content>
              <List.Content header="Χωρίς ομάδα" />
            </List.Item>

            { runs.data?.map(({ id, size, created_at }) => (
                <List.Item key={id} as={NavLink} to={`${match.url}/${id}${pathSuffix}`} >
                  <List.Content floated="right">
                    <Label color="teal" content={size} title={`${size} κωδικ${size === 1 ? 'ός' : 'οί'}`} /> 
                  </List.Content>

                  <List.Content>
                    <List.Header>
                      Ομάδα #{id}
                    </List.Header>
                    <List.Description>
                      { new Date(created_at).toLocaleString('el') }
                    </List.Description>
                  </List.Content>

                </List.Item>
              )
            ) }
          </List>

          <Divider />

          <Container textAlign="center">
            <Popup flowing
              trigger={<Button fluid positive icon="plus" labelPosition="left" content="Νέα ομάδα" />}
              on="click" position="top left"
              open={newRunOpen}
              onClose={() => setNewRunOpen(false)}
              onOpen={() => setNewRunOpen(true)}
              >
              <Popup.Content>
                <Form onSubmit={() => newRunMutation.mutate()}>
                  <Form.Input type="number" value={newRunSize} onChange={handleChangeRunSize} label="Μέγεθος" />
                  <Button primary content="Δημιουργία ομάδας" />
                  <Button type="button" content="Επιστροφή" onClick={() => setNewRunOpen(false)} />
                </Form>
              </Popup.Content>
            </Popup>
          </Container>

        </Grid.Column>
        <Grid.Column width={12}>
          <Route path={`${match.path}/:runId(\\d+)?`}>
            <BarcodeSheets />
          </Route>
        </Grid.Column>
      </Grid.Row>

    </Grid>
  );
}


export default function BarcodesAdmin() {
  const match = useRouteMatch();

  const pageTitle = 'Κωδικοί barcode';
  return (
    <Container>
      <Helmet title={pageTitle} />
      <Header as="h1" textAlign="center" className="colored atmgreen" content={pageTitle} />
      <Divider className="orange" />
      <Divider hidden />

      <Menu secondary compact size="huge">
        <Menu.Item content="Aναζήτηση" as={NavLink} to={match.url} exact />
        <Menu.Item content="Διαχείριση" as={NavLink} to={`${match.url}/sheets`} />
      </Menu>

      <Divider />

      <Switch>
        <Route path={`${match.path}/sheets`}>
          <BarcodeSheetRuns />
        </Route>

        <Route>
          <Container text>
            <BarcodeAssignment.Form admin />
          </Container>
        </Route>
      </Switch>

      <Divider hidden />
    </Container>
  );
}
