// eslint-disable-next-line no-unused-vars
import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useQuery } from 'react-query';
import { Helmet } from 'react-helmet';

import { Container, Divider, Header, Icon, Message } from 'semantic-ui-react';

import axios from 'axios';

import PointsBalance from './PointsBalance';

import useProfile from '../queries/useProfile';


export default function ContributorProfile({ refetchOnMount=true }) {
  const profile = useProfile();
  const contributor = profile.data?.contributor;

  // // on each mount, refresh the profile
  // useEffect(() => {
  //   if (refetchOnMount) profile.refetch();
  // }, [refetchOnMount]);

  const {data: activeMunicipalities} = useQuery('activeMunicipalities',
  () => axios.get('/api/municipalities_infos/').then(({data}) => data));

  const userMunicipalityInfo = activeMunicipalities?.find(({ municipality }) =>
    municipality === contributor?.municipality
  );

  const [, ...userMunicipalityName] = contributor?.municipality?.split(' ') ?? ['', ''];

  const pageTitle = 'Λογαριασμός';
  return (<>
    <Helmet title={pageTitle} />
    <Container>
      <Header as="h1" textAlign="center" className="colored atmgreen" content={pageTitle} />
      <Divider className="orange" />
    </Container>

    <Container text>
      <Divider hidden />
      <PointsBalance />

      <Divider hidden />

      { contributor?.sheets_count === 0 &&
        <Message icon warning>
          <Icon name="warning sign" />
          <Message.Content>
            <p> Προκειμένου να συλλέξεις πόντους με την παράδοση των τηγανέλαιων, πρέπει πρώτα να προμηθευτείς
              τα αυτοκόλλητα με τους κωδικούς barcode. </p>
            {contributor?.can_contribute ?
              <p>Μπορείς να επικοινωνήσεις με τον <a href={userMunicipalityInfo?.url}>Δήμο {userMunicipalityName?.join(' ')}</a> στο τηλέφωνο <a href={`tel:+30${userMunicipalityInfo?.tel}`}>{userMunicipalityInfo?.tel}</a> για να προμηθευτείς αυτοκόλλητα.</p> :
              <p>
                Στον Δήμο σου αυτή τη στιγμή δεν έχει ξεκινήσει η διαδικασία συλλογής.
                Μπορείς να δεις <Link to="/questions#where">εδώ</Link> σε ποιους Δήμους έχει ξεκινήσει ήδη. </p>
            }
            <p>Όταν προμηθευτείς τα αυτοκόλλητα barcode, θα έχουν ήδη ενεργοποιηθεί, αλλιώς μπορείς να τα <Link to="/barcodes">ενεργοποιήσεις και εδώ</Link>.</p>
          </Message.Content>
        </Message>
      }

      { contributor?.sheets_count > 0 &&
        <Message icon positive>
          <Icon name="info circle" />
          <Message.Content>
            <p>
              Έχεις ενεργοποιήσει <strong>{contributor.sheets_count}</strong>
              {contributor.sheets_count > 1 ? ' φύλλα ' : ' φύλλο '}
              με αυτοκόλλητα κωδικών barcode.
            </p>

            <p>Αν έχεις ήδη παραδόσει μπουκάλι με το αυτοκόλλητο στο καπάκι του, μπορείς να ελέγξεις για την καταγραφή του στο <Link to="/collections">ιστορικό παράδοσης μπουκαλιών</Link>.</p>

            <p>Αν έχεις προμηθευτεί πρόσθετα φύλλα με αυτοκόλλητα, μπορείς να τα <Link to="/barcodes">ενεργοποιήσεις εδώ</Link>.</p>
          </Message.Content>
        </Message>
      }

      <Divider section hidden />
    </Container>
  </>);
}
