import { NavLink, useLocation } from 'react-router-dom';
import { Menu } from 'semantic-ui-react';

import { ResponsiveContainer } from '@zerowaste/components';
import InfoMessage from './InfoMessage';

import useProfile from '../queries/useProfile';

import qs from 'qs';

export default function WasteOilContainer({
  children,
  onLogin,
  onRegister,
  onLogout,
  showAuth = true,
}) {
  const profile = useProfile();
  const user = profile.data;

  const contributor = user?.contributor;
  const isSupervisor = user?.is_supervisor;
  const isStaff = user && (user.is_employee || user.is_supervisor);

  const location = useLocation();
  const { code } = qs.parse(location.search, { ignoreQueryPrefix: true });

  return (
    <ResponsiveContainer
      headerTitle="Πλατφόρμα ανακύκλωσης τηγανέλαιων"
      className="header-menu"
      userPaths={['/profile', '/barcodes', '/collections']}
      contentPaths={['/', '/recycle', '/questions', '/news']}
      user={user}
      showLoginRegister={profile.isFetched && !code}
      onLogin={onLogin}
      onLogout={onLogout}
      onRegister={onRegister}
      showAuth={showAuth}
      notLoggedIn={profile.isFetched && !profile.data}
      displaySettings={!!contributor}
      menuItems={
        <>
          <Menu.Item style={{ flexShrink: 1 }} as={NavLink} to="/" exact>
            Αρχικη
          </Menu.Item>
          <Menu.Item style={{ flexShrink: 1 }} as={NavLink} to="/recycle">
            Οδηγιες ανακυκλωσης
          </Menu.Item>
          <Menu.Item style={{ flexShrink: 1 }} as={NavLink} to="/points">
            Σημεια συλλογης
          </Menu.Item>
          <Menu.Item style={{ flexShrink: 1 }} as={NavLink} to="/questions">
            Συχνες ερωτησεις
          </Menu.Item>
          <Menu.Item style={{ flexShrink: 1 }} as={NavLink} to="/news">
            Νεα
          </Menu.Item>
        </>
      }
      userMenu={
        contributor &&
        showAuth && (
          <>
            <Menu.Item as={NavLink} to="/profile">
              Λογαριασμός
            </Menu.Item>
            <Menu.Item as={NavLink} to="/barcodes">
              Ενεργοποίηση Barcode
            </Menu.Item>
            <Menu.Item as={NavLink} to="/collections">
              Ιστορικό παράδοσης μπουκαλιών
            </Menu.Item>
          </>
        )
      }
      notLoggedInMenu={
        profile.isFetched &&
        !profile.data && (
          <InfoMessage
            style={{
              width: 'unset',
              margin: 0,
              padding: '6px 0',
            }}
          />
        )
      }
      adminMenu={
        isStaff &&
        showAuth && (
          <>
            {isSupervisor && (
              <Menu.Item as={NavLink} to="/admin/dashboard">
                Επισκοπηση
              </Menu.Item>
            )}
            <Menu.Item as={NavLink} to="/admin/collect">
              Συλλογη
            </Menu.Item>
            <Menu.Item as={NavLink} to="/admin/collections">
              Ιστορικο
            </Menu.Item>
            {isSupervisor && (
              <Menu.Item as={NavLink} to="/admin/contributors">
                Χρήστες
              </Menu.Item>
            )}
            {isSupervisor && (
              <Menu.Item as={NavLink} to="/admin/barcodes">
                Barcodes
              </Menu.Item>
            )}
            {isSupervisor && (
              <Menu.Item as={NavLink} to="/admin/settings">
                Ρυθμισεις
              </Menu.Item>
            )}
            {isSupervisor && (
              <Menu.Item as={NavLink} to="/admin/news">
                Νεα
              </Menu.Item>
            )}
          </>
        )
      }
    >
      {children}
    </ResponsiveContainer>
  );
}
