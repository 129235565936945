import { Fragment, useState } from 'react';
import { useQuery } from 'react-query';
import { Route, Link, NavLink, useLocation, useRouteMatch, matchPath } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import { List, Label, Menu, Message, Loader } from 'semantic-ui-react';

import { SearchFilterInput, useDebounce } from '@zerowaste/components';

import { useContributors } from '../queries/contributors';

import axios from 'axios';


export default function ContributorsList({ as: Root=Fragment, listHeight, parentPageTitle, parentMatch, pathSuffix='', filter, ...props }) {
  const match = useRouteMatch();
  const location = useLocation();
  const { search } = useLocation();

  const contributorActive = !!matchPath(location.pathname, `${match.path}/:contributorId(\\d+)`);

  const [contributorSearch, setContributorSearch] = useState('');
  const debouncedContributorSearch = useDebounce(contributorSearch, 300);

  const contributors = useContributors({ search: debouncedContributorSearch });
  const noContributors = !debouncedContributorSearch && contributors.data?.length === 0;

  const contributorsStats = useQuery('collections_stats',
    () => axios.get('/api/collections/counts/').then(({data}) => data)
  );
  const totalRecords = contributorsStats.data?.collections_count;

  const searchInput = (
    <SearchFilterInput
      loading={contributors.isFetching}
      disabled={!debouncedContributorSearch && contributors.data?.length === 0}
      value={contributorSearch}
      setValue={setContributorSearch}
    />
  );

  return (
    <Root {...props}>
      { !filter && <>
        <Menu secondary pointing size="huge">
          <Menu.Item as={NavLink} to={parentMatch.url} content="Χρήστες" />
        </Menu>
        { searchInput }
      </> }

      <List selection size="large" className="narrow-list">
        { filter && <>
          <List.Item as={Link} to={{ pathname: parentMatch.url, search }} active={!contributorActive}>
            <List.Content floated="right">
              <Label color="teal" title={totalRecords !== undefined ? `${totalRecords} εγγραφές` : undefined}>
                { totalRecords !== undefined ? totalRecords : <>&hellip;</> }
              </Label>
            </List.Content>
            <List.Header content="Όλοι οι χρήστες" />
          </List.Item>
          <List.Item>
            { searchInput }
          </List.Item>
        </> }
      </List>

      <div style={{position: 'relative', height: listHeight, overflow: listHeight ? 'auto' : undefined }}>

        { contributors.isLoading && <Loader active /> }
        { contributors.isError && <Message error content="Οι πληροφορίες για τους χρήστες δεν είναι διαθέσιμες." /> }
        { noContributors && <Message header="Δεν υπάρχουν εγγεγραμμένοι χρήστες." /> }

        <List selection divided className="narrow-list" size="large">
          { contributors.data?.map(({ id, full_name, email, collections_count }) => <Fragment key={id}>
              {/* Add user name to page title, by using a route-based head rendering method */}
              <Route path={`${parentMatch.path}/${id}`} exact>
                <Helmet title={`${full_name || 'Άγνωστο όνομα'} | ${parentPageTitle}`} />
              </Route>
              <Route path={`${parentMatch.path}/${id}/barcodes`}>
                <Helmet title={`Ενεργοποίηση barcode | ${full_name || 'Άγνωστο όνομα'} | ${parentPageTitle}`} />
              </Route>

              <List.Item as={NavLink} to={{ pathname: `${parentMatch.url}/${id}${pathSuffix}`, search }}>
                { filter &&
                  <List.Content floated="right">
                    <Label color="teal" content={collections_count} title={`${collections_count} εγγραφές`} /> 
                  </List.Content>
                }
                <List.Content title={`${ full_name || 'Άγνωστο όνομα' } (${email})`}>
                  <List.Header>{ full_name || 'Άγνωστο όνομα' }</List.Header>
                  <List.Description>
                    { email }
                  </List.Description>
                </List.Content>
              </List.Item>
            </Fragment>
          ) }
        </List>

      </div>

    </Root>
  );
}
