import { Container, Divider, Grid, Header, Image, List, Segment } from 'semantic-ui-react';
import { Helmet } from 'react-helmet';

import bottlesImg from '../images/cookingoils-bottles.png';
import cookingOilsOnlyImg from '../images/cookingoils-only.png';
import binImg from '../images/cookingoils-bin.png';
import bottleImg from '../images/cookingoils-bottle.png';
import atmBottleImg from '../images/atm-bottle.png';
import atmImg from '../images/atm.png';
import barcodeImg from '../images/barcode-sample.png';


export default function WasteOilRecycle() {
  const pageTitle = 'Οδηγίες ανακύκλωσης τηγανέλαιων';
  return (<>
    <Helmet title={pageTitle} />

    <Container text textAlign="center">
      <Header as="h1" className="colored atmgreen" content={pageTitle} />

      <Grid className="tablet only computer only" centered verticalAlign="middle" as={Segment} basic size="small">
        <Grid.Column width={6} only="computer tablet">
          <Image inline src={bottlesImg} />
        </Grid.Column>
        <Grid.Column width={2} />
        <Grid.Column width={6} only="computer tablet">
          <Image inline src={cookingOilsOnlyImg} />
        </Grid.Column>
      </Grid>

    </Container>

    <Container>
      <Divider className="orange" />
      <Divider section hidden />
    </Container>

    <Container text>
      <Header as="h2" >Πού μπορώ να αφήσω τα τηγανέλαια;</Header>

      <List relaxed bulleted className="decorated orange">
        <List.Item><span>Στον πορτοκαλί κάδο της ΔΙΑΔΥΜΑ μέσα σε οποιοδήποτε πλαστικό μπουκάλι χωρητικότητας 0.5, 1, 1.5 ή 2 λίτρων.</span></List.Item>
        <List.Item><span>Στο ΑΤΜ ανακύκλωσης τηγανέλαιων μέσα στο ειδικό μπουκάλι των ΑΤΜ.</span></List.Item>
      </List>

      <Divider section />

      <Header as="h2" className="colored binorange">Πορτοκαλι καδος</Header>

      <Header as="h3">Χρήσιμες οδηγίες για την αποθήκευση των τηγανέλαιων στον πορτοκαλί κάδο</Header>

      <Grid stackable>
        <Grid.Column width={7}>
          <Grid columns={2} textAlign="center">
            <Grid.Row verticalAlign="bottom">
              <Grid.Column><Image inline src={binImg} size="small" /></Grid.Column>
              <Grid.Column><Image inline size="mini" src={bottleImg} /></Grid.Column>
            </Grid.Row>
            <Grid.Row as={Segment} basic attached size="small" className="colored">
              <Grid.Column>Πορτοκαλί κάδος</Grid.Column>
              <Grid.Column>Πλαστικό μπουκάλι λαδιού, αναψυκτικού, ή νερού</Grid.Column>
            </Grid.Row>
          </Grid>
        </Grid.Column>
        <Grid.Column width={9}>
          <List relaxed ordered className="decimal">
            <List.Item>Αφήνουμε το λάδι να κρυώσει.</List.Item>
            <List.Item>Βεβαιωνόμαστε ότι το πλαστικό μπουκάλι που θα χρησιμοποιήσουμε είναι στεγνό (χωρίς νερό, ή κάποιο άλλο υγρό). Αν προηγουμένως είχε λάδι δεν υπάρχει πρόβλημα.</List.Item>
            <List.Item>Χρησιμοποιούμε ένα χωνί για να περάσουμε το λάδι στο πλαστικό μπουκάλι. Αν χρειαστεί το περνάμε από ένα σουρωτήρι για να το απαλλάξουμε από υπολείμματα τροφών.</List.Item>
            <List.Item>Κλείνουμε καλά το μπουκάλι και τοποθετούμε το αυτοκόλλητο με τον κωδικό barcode σε καθαρό σημείο του μπουκαλιού.</List.Item>
            <List.Item>Τοποθετούμε το μπουκάλι όπως είναι μέσα στον πορτοκαλί κάδο, χωρίς να το αδειάσουμε και χωρίς σακούλα.</List.Item>
          </List>

          <p className="alert"><strong>Σημαντικό! Δεν χρησιμοποιούμε γυάλινα δοχεία.</strong></p>
        </Grid.Column>
      </Grid>

      <Divider section />

      <Header as="h2" className="colored atmgreen">ATM</Header>

      <Header as="h3">Χρήσιμες οδηγίες για την αποθήκευση των τηγανέλαιων στο ΑΤΜ ανακύκλωσης τηγανέλαιων</Header>

      <Grid stackable>
        <Grid.Column width={7}>
          <Grid columns={2} textAlign="center">
            <Grid.Row verticalAlign="bottom">
              <Grid.Column><Image inline src={atmImg} size="small" /></Grid.Column>
              <Grid.Column><Image inline size="tiny" src={atmBottleImg} /></Grid.Column>
            </Grid.Row>
            <Grid.Row as={Segment} basic attached size="small" className="colored">
              <Grid.Column>ΑΤΜ τηγανέλαιων</Grid.Column>
              <Grid.Column>Ειδικό μπουκάλι ΑΤΜ τηγανέλαιων</Grid.Column>
            </Grid.Row>
          </Grid>
        </Grid.Column>
        <Grid.Column width={9}>
          <List relaxed ordered className="decimal">
            <List.Item>Αφήνουμε το λάδι να κρυώσει.</List.Item>
            <List.Item>Χρησιμοποιούμε ένα χωνί για να περάσουμε το λάδι στο ειδικό μπουκάλι των ΑΤΜ. Αν χρειαστεί το περνάμε από ένα σουρωτήρι για να το απαλλάξουμε από υπολείμματα τροφών.</List.Item>
            <List.Item>Κλείνουμε καλά το καπάκι και τοποθετούμε το αυτοκόλλητο με τον κωδικό barcode πάνω του.</List.Item>
            <List.Item>Μεταφέρουμε το μπουκάλι στο ΑΤΜ και το εισάγουμε στη θυρίδα συλλογής που βρίσκεται στο επάνω μέρος του μηχανήματος (χωρίς σακούλα)</List.Item>
            <List.Item>Περιμένουμε να μας δώσει το ΑΤΜ από την κάτω θυρίδα το νέο, καθαρό ειδικό μπουκάλι για το επόμενο γέμισμα.</List.Item>
          </List>

          <p className="alert"><strong>Σημαντικό! Δεν χρησιμοποιούμε γυάλινα δοχεία.</strong></p>
        </Grid.Column>
      </Grid>

      <Divider section />

      <Header as="h2" className="colored">Αυτοκολλητο barcode</Header>

      <Grid stackable>
        <Grid.Column width={7} textAlign="center">
          <Image inline src={barcodeImg} size="medium" />
        </Grid.Column>
        <Grid.Column width={9}>
          <p>
            Το αυτοκόλλητο barcode είναι ένας μοναδικός κωδικός ανά εγγεγραμμένο χρήστη, ο οποίος μας βοηθάει να αποδοθούν οι πόντοι σωστά. Όταν λάβεις την καρτέλα με κωδικούς barcode μην ξεχάσεις να την ενεργοποιήσεις στο λογαριασμό σου, έτσι ώστε να σε αναγνωρίζει το σύστημα.
          </p>

          <p><strong>
            Να σημειωθεί ότι ο κωδικός barcode δεν είναι απαραίτητος για την ανακύκλωση των τηγανέλαιων, παρά μόνο για τη συλλογή πόντων του χρήστη. Συνεπώς, μπορείς να συμμετέχεις στην ανακύκλωση τηγανέλαιων και χωρίς αυτόν.
          </strong></p>
        </Grid.Column>
      </Grid>

      {/* <Header size="medium">Μάθε εδώ πώς θα ενεργοποιήσεις το barcode σου</Header> */}

      <Divider hidden />
    </Container>

    <Segment padded="very" basic attached className="colored lightgreen">
      <Container text>
        <Header as="h3">Τα μεγαλύτερα προβλήματα από τη λανθασμένη διαχείριση των τηγανέλαιων:</Header>

        <List relaxed bulleted className="decorated orange">
          <List.Item><span>Θέτει σε κίνδυνο την παραγωγικότητα του εδάφους</span></List.Item>
          <List.Item><span>Απειλεί τη υδρόβια και θαλάσσια ζωή</span></List.Item>
          <List.Item><span>Είναι ένας από τους κύριους παράγοντες ρύπανσης υδάτων</span></List.Item>
          <List.Item><span>Δυσχεραίνει το σύστημα καθαρισμού του νερού στους βιολογικούς καθαρισμούς</span></List.Item>
          <List.Item><span>Αυξάνει το κόστος καθαρισμού του νερού</span></List.Item>
          <List.Item><span>Αυξάνει την πιθανότητα φράξης των αποχετευτικού συστήματος</span></List.Item>
        </List>

        <Divider />

        <Header as="h3">Τα περιβαλλοντικά οφέλη από την ανακύκλωση του μαγειρικού λαδιού είναι:</Header>

        <List relaxed bulleted className="decorated green">
          <List.Item><span>Η μείωση των αποβλήτων που ρυπαίνουν το νερό</span></List.Item>
          <List.Item><span>Η επαναχρησιμοποίηση του καθαρισμένου νερού</span></List.Item>
          <List.Item><span>Χαμηλότερο κόστος επεξεργασίας λυμάτων (βιολ. καθαρισμός)</span></List.Item>
          <List.Item><span>Μείωση της επιδείνωσης των αποχετευτικών συστημάτων</span></List.Item>
          <List.Item><span>Μείωση της εξάπλωσης των μικροοργανισμών που είναι επιβλαβείς για την υγεία</span></List.Item>
          <List.Item><span>Μείωση της εξάρτησης για ενέργεια από το εξωτερικό</span></List.Item>
        </List>
      </Container>
    </Segment>

    <Segment padded="very" basic attached className="colored brightgreen">
      <Container text textAlign="center">
        <Header as="h2" inverted>
          Συνεπώς δεν πρέπει να πετάμε/αδειάζουμε τα τηγανέλαια στον νεροχύτη, την τουαλέτα ή το έδαφος!!! Ούτε στα σκουπίδια!!!
        </Header>

        <Divider hidden />

        <Header as="h2" inverted>
          Τα συλλέγουμε χωριστά σε πλαστικά μπουκάλια και τα μεταφέρουμε στο πιο κοντινό σημείο ανακύκλωσης.
        </Header>
      </Container>
    </Segment>

  </>);
}
