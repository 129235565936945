import { useState, useEffect, useRef } from 'react';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import { Helmet } from 'react-helmet';

import { Container, Message, Segment, Header, Button, Divider, Form, Input, Radio, Icon } from 'semantic-ui-react';

import axios from 'axios';


function CollectionsAdmin() {
  const queryClient = useQueryClient();
  const barcodeRef = useRef();

  const [scanCode, setScanCode] = useState('');
  const [containerId, setContainerId] = useState();

  const [containerFormFocused, setContainerFormFocused] = useState(false);

  const { data: containers, refetch: refetchContainers } = useQuery('containers',
    () => axios.get('/api/containers/').then(({data}) => data),
    { 
      // select the first in the list as default
      onSuccess: (data) => setContainerId(data[0]?.id),
      enabled: false,
      cacheTime: 0,
    }
  );

  const scanMutation = useMutation(
    (scanParams) => axios.post('/api/collections/', scanParams),
    { onSuccess: () => queryClient.invalidateQueries('collections') }
  );

  const cancelScan = useMutation(
    (eventId) => axios.post(`/api/collections/${eventId}/cancel/`),
    { onSuccess: () => queryClient.invalidateQueries('collections') }
  );

  const lastScanData = scanMutation.data?.data;
  const scanErrorData = scanMutation.error?.response.data;
  const isCodeUnassigned = (scanErrorData?.non_field_errors || '')[0] === 'unassigned_code';

  // fetch containers on mount just once
  useEffect(() => refetchContainers(), [refetchContainers]);

  // reset form data after successful submit
  useEffect(() => {
    if (scanMutation.isSuccess) {
      // reset the default
      // setContainerId(containers[0].id);
      setScanCode('');
      barcodeRef.current.focus();
    }
  }, [scanMutation.isSuccess]);

  useEffect(() => {
    if (scanCode) {
      scanMutation.reset();
      cancelScan.reset();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scanCode]);

  const handleSubmit = () => scanMutation.mutate({ container: containerId, batch_id_code: scanCode });
  const handleDismiss = () => {
    cancelScan.reset();
    scanMutation.reset();
    barcodeRef.current.focus();
  };
  const handleCancel = () => {
    cancelScan.mutate(scanMutation.data.data.id, {
      onSuccess: () => scanMutation.reset()
    });
  };

  const pageTitle = 'Συλλογή μπουκαλιών';
  return (<>
    <Helmet title={pageTitle} />

    <Container>
      <Header as="h1" textAlign="center" className="colored atmgreen" content={pageTitle} />
      <Divider className="orange" />
      <Divider hidden />
    </Container>

    <Container text>
      <Segment>
        <Form onSubmit={handleSubmit} error={scanMutation.isError && !isCodeUnassigned} success={scanMutation.isSuccess} size="large">

          <Form.Field>
            <label>Barcode</label>
            <Input ref={barcodeRef} icon="barcode" iconPosition="left" size="huge" 
              value={scanCode}
              onChange={(e, {value}) => setScanCode(value)}
              autoFocus
            />
          </Form.Field>

          <Form.Field inline>
            <label>Τύπος μπουκαλιού</label>
            <Form.Group grouped as={Segment} style={{ marginTop: '1ex', paddingTop: 0, paddingBottom: 0, borderColor: containerFormFocused ? '#85b7d9' : undefined }}>
              { containers?.map(({ id, label }) =>
                  <Form.Field key={id}>
                    <Radio
                        onFocus={() => setContainerFormFocused(true)}
                        onBlur={() => setContainerFormFocused(false)}
                        label={label}
                        name="container"
                        value={id}
                        checked={containerId === id}
                        onChange={() => setContainerId(id)}
                    />
                  </Form.Field>
                )
              }
            </Form.Group>
          </Form.Field>
          <Form.Button primary floated="right" disabled={!containerId || !scanCode} loading={scanMutation.isLoading}>Υποβολή</Form.Button>

          <Divider hidden clearing />

          <Message size="small" success onDismiss={handleDismiss} icon>
            <Icon name="check circle" />
            <Message.Content>
              <Message.Header>
                Η σάρωση του barcode "{lastScanData?.batch_id_code}" ήταν επιτυχής.
              </Message.Header>
              Σε περίπτωση λάθους, μπορείτε να ακυρώσετε την σάρωση άμεσα.&nbsp;
              <Divider fitted hidden />
              <Button compact negative content="Ακύρωση" type="button" onClick={handleCancel} loading={cancelScan.isLoading} />
            </Message.Content>
          </Message>

          <Message error size="small"
            header="Σφάλμα κατά την σάρωση κωδικού."
            content="Παρακαλώ προσπαθήστε ξανά."
            onDismiss={handleDismiss}
          />

        </Form>

        { isCodeUnassigned &&
          <Message error
            header="Σφάλμα κατά την σάρωση κωδικού."
            content="Σαρώσατε κωδικό που δεν έχει ανατεθεί ακόμα σε κάποιο εγγεγραμμένο χρήστη."
            onDismiss={handleDismiss} 
          />
        }

        { cancelScan.isSuccess &&
          <Message success onDismiss={handleDismiss} header="Η σάρωση ακυρώθηκε." />
        }
        { cancelScan.isError &&
          <Message error header="Σφάλμα κατά την ακύρωση της σάρωσης. Παρακαλώ προσπαθήστε ξανά." />
        }

      </Segment>

      <Divider section hidden />
    </Container>
  </>);
}

export default CollectionsAdmin;
