import { useEffect } from 'react';
import { Route, Switch, Redirect, useHistory, useLocation } from 'react-router-dom';

import { Loader } from 'semantic-ui-react';

import { Announcements, useToken } from '@zerowaste/components';

import ContributorProfile from './ContributorProfile';
import CollectionsHistory from './CollectionsHistory';
import Barcodes from './Barcodes';
import FaqPage from './FaqPage';
import WasteOilIntro from './WasteOilIntro';
import WasteOilRecycle from './WasteOilRecycle';
import WasteOilPoints from './WasteOilPoints';
import Terms from './Terms';

import useProfile from '../queries/useProfile';

import axios from 'axios';
import qs from 'qs';


function ExecuteEffect({ onMount }) {
  useEffect(onMount);
  return null;
}


function WasteOilHome({ onLogin, onRegister, registerUrl }) {
  const location = useLocation();
  const history = useHistory();

  const { setToken } = useToken();

  const { code, state: returnTo } = qs.parse(location.search, { ignoreQueryPrefix: true });

  useEffect(
    () => {
      if (!code) return;

      axios.post('/api/zerowaste/auth/', { code }).then(
        ({ data }) => {
          setToken(data.token);
          history.replace(returnTo);
        },
        (err) => {
          // TODO show something to the user ???
          console.warn(err);
          // just remove the code part, and stay at home page
          history.replace({ search: '' });
        }
      );

    },
    [code, returnTo, setToken, history]
  );

  const profile = useProfile();
  const unknownOrContributor = (!profile.data || profile.data.contributor);

  return (<>
    <Switch>
      <Route path="/" exact>
        <WasteOilIntro onRegister={onRegister('/profile')} />
      </Route>

      <Route path="/recycle">
        <WasteOilRecycle />
      </Route>

      <Route path="/points">
        <WasteOilPoints />
      </Route>

      <Route path="/questions">
        <FaqPage registerUrl={registerUrl} />
      </Route>

      <Route path="/news">
        <Announcements />
      </Route>

      <Route path="/terms">
        <Terms />
      </Route>

      { profile.isFetched && !code && unknownOrContributor && <>
        <Route key="profile" path="/profile">
          { profile.data?.contributor ?
              <ContributorProfile refetchOnMount={!code} />
            : <ExecuteEffect onMount={onLogin('/profile')} />
          }
        </Route>

        <Route key="barcodes" path="/barcodes">
          { profile.data?.contributor ?
              <Barcodes />
            : <ExecuteEffect onMount={onLogin('/barcodes')} />
          }
        </Route>

        <Route key="collections" path="/collections/:pageKey(page)?/:page(\d+)?">
          { profile.data?.contributor ?
              <CollectionsHistory />
            : <ExecuteEffect onMount={onLogin('/collections')} />
          }
        </Route>
      </>}

      {/* if we are still loading the user, let's show a loader instead of redirecting */}
      <Route path="*">
        { !profile.isFetched || code ? <Loader inline="centered" size="massive" active /> : <Redirect to="/" /> }
      </Route>

    </Switch>
  </>);
}

export default WasteOilHome;
