import { Link } from 'react-router-dom';

import { Icon, Message } from 'semantic-ui-react';

function InfoMessage({ size = 'large', style }) {
  return (
    <Message
      size={size}
      success
      icon
      style={{
        backgroundColor: 'transparent',
        boxShadow: 'none',
        justifyContent: 'center',
        color: 'white',
        paddingTop: '.5em',
        paddingBottom: '.5em',
        ...style,
      }}
    >
      <Icon name="info circle" style={{lineHeight: 1, fontSize: '2.5em'}} />
      <Message.Content style={{ maxWidth: 'max-content' }}>
        Δες{' '}
        <Link
          to="/questions#where"
          style={{
            color: 'inherit',
            fontWeight: 'bold',
            textDecoration: 'underline',
          }}
        >
          εδώ
        </Link>{' '}
        τους Δήμους στους οποίους έχει ξεκινήσει η συλλογή των τηγανέλαιων
      </Message.Content>
    </Message>
  );
}

export default InfoMessage;
