import { Fragment, useRef } from 'react';
import { Link, Route, Redirect, useParams, useRouteMatch, useHistory, generatePath } from 'react-router-dom';

import { Message, Table, Header, Checkbox, Grid, Icon, Button, Card, Segment, Modal, Sticky, Ref } from 'semantic-ui-react';
import { useAvailableBarcodes, useRegisteredBarcodes } from '../queries/barcodes';

import BarcodeAssignment from './BarcodeAssignment';
import { OrientedPagination } from '@zerowaste/components';

import { omit } from 'lodash';


function BarcodesTableMobile({ admin, contributorId, sheets, allSelected, noneSelected, selectedSheets, onToggleSelectAll, onRowSelection, ...props }) {
  return (
    <Table celled {...props} unstackable definition>
      <Table.Body>
        { selectedSheets && sheets.data?.count > 0 &&
            <Table.Row>
              <Table.Cell />
              <Table.Cell />
              <Table.Cell textAlign="center">
                <Checkbox indeterminate={!allSelected && !noneSelected} checked={!noneSelected}
                  onChange={(e, { checked }) => onToggleSelectAll(checked) }
                />
              </Table.Cell>
            </Table.Row>
        }
        { sheets.data?.results.map(({id, id_code, registered_at, collected_count, contributor }, index) =>
            <Fragment key={id}>
              { index > 0 &&
                <Table.Row><Table.Cell style={{ backgroundColor: 'white' }} colSpan={selectedSheets ? 3 : 2} /></Table.Row>
              }

              <Table.Row>
                <Table.Cell>Barcode</Table.Cell>
                <Table.Cell>{id_code}</Table.Cell>
                { selectedSheets && 
                    <Table.Cell rowSpan={4} textAlign="center">
                      <Checkbox
                        checked={selectedSheets.includes(id)}
                        onChange={(e, { checked }) => onRowSelection(id, checked)} />
                    </Table.Cell>
                }
              </Table.Row>

              { admin && !contributorId &&
                <Table.Row>
                  <Table.Cell>Χρήστης</Table.Cell>
                  <Table.Cell>{ contributor ? <Link to={`/admin/contributors/${contributor.id}`}>{contributor.full_name || 'Άγνωστο όνομα'}</Link> : '-' }</Table.Cell>
                </Table.Row>
              }

              <Table.Row>
                <Table.Cell>Ημερομηνία ενεργοποίησης</Table.Cell>
                <Table.Cell>{registered_at ? new Date(registered_at).toLocaleString('el') : '-'}</Table.Cell>
              </Table.Row>

              <Table.Row>
                <Table.Cell>Συλλεχθέντα μπουκάλια</Table.Cell>
                <Table.Cell>{collected_count}</Table.Cell>
              </Table.Row>

            </Fragment>
          )
        }
        { sheets.data?.count === 0 &&
          <Table.Row textAlign="center">
            <Table.Cell>
              Δεν εντοπίστηκαν κωδικοί barcode.
            </Table.Cell>
          </Table.Row>
        }
      </Table.Body>
    </Table>
  );
}


function BarcodesTableComputer({ admin, contributorId, sheets, allSelected, noneSelected, selectedSheets, onToggleSelectAll, onRowSelection, ...props }) {
  let tableCols = 3;
  if (admin && !contributorId) tableCols += 1;
  if (selectedSheets && sheets.data?.count > 0) tableCols += 1;

  return (
    <Table celled {...props}>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell>Barcode</Table.HeaderCell>
          { admin && !contributorId && <Table.HeaderCell>Χρήστης</Table.HeaderCell>}
          <Table.HeaderCell>Ημερομηνία ενεργοποίησης</Table.HeaderCell>
          <Table.HeaderCell>Συλλεχθέντα μπουκάλια</Table.HeaderCell>
          { selectedSheets && sheets.data?.count > 0 &&
              <Table.HeaderCell collapsing>
                <Checkbox indeterminate={!allSelected && !noneSelected} checked={!noneSelected}
                  onChange={(e, { checked }) => onToggleSelectAll(checked) }
                />
              </Table.HeaderCell>
          }
        </Table.Row>
        </Table.Header>
        <Table.Body>
          { sheets.data?.results.map(({id, id_code, registered_at, collected_count, contributor }) =>
              <Table.Row key={id_code}>
                <Table.Cell>{id_code}</Table.Cell>
                { admin && !contributorId && <Table.Cell>{ contributor ? <Link to={`/admin/contributors/${contributor.id}`}>{contributor.full_name || 'Άγνωστο όνομα'}</Link> : '-' }</Table.Cell> }
                <Table.Cell>{registered_at ? new Date(registered_at).toLocaleString('el') : '-'}</Table.Cell>
                <Table.Cell>{collected_count}</Table.Cell>
                { selectedSheets && 
                    <Table.Cell collapsing>
                      <Checkbox
                        checked={selectedSheets.includes(id)}
                        onChange={(e, { checked }) => onRowSelection(id, checked)} />
                    </Table.Cell>
                }
              </Table.Row>
            )
          }
          { sheets.data?.count === 0 &&
            <Table.Row textAlign="center">
              <Table.Cell colSpan={tableCols}>
                Δεν εντοπίστηκαν κωδικοί barcode.
              </Table.Cell>
            </Table.Row>
          }
        </Table.Body>
    </Table>
  );
}


export function BarcodesRegistered({ admin, setSelected, selectedSheets, ...props }) {
  const contextRef = useRef();

  const history = useHistory();
  const match = useRouteMatch();

  const params = useParams();
  const { contributorId, runId: printRun, page } = params;

  // get the base path without the paging params
  const baseUrl = generatePath(match.path, omit(params, ['page', 'pageKey']));
  const sheets = useRegisteredBarcodes(
    { printRun: printRun || ( (admin && !contributorId) ? 'none' : undefined ), contributor: contributorId, page, pageSize: 10 },
    { onPageInvalid: () => history.replace(baseUrl) }
  );

  if (!sheets.isPreviousData && sheets.data?.total_pages > 1 && !page) {
    return <Redirect to={`${baseUrl}/page/1`} />
  }

  if (sheets.isLoading) {
    return <Segment basic placeholder loading />
  }
  if (sheets.isError) {
    return <Message error content="Οι πληροφορίες για ενεργοποιημένους κωδικούς δεν είναι διαθέσιμες αυτή τη στιγμή." />;
  }

  if ( sheets.data?.count === 0 ) {
    return contributorId ? null : (
      <Segment basic textAlign="center" size="large">
        <Message compact>
          Δεν υπάρχουν ενεργοποιημένοι κωδικοί barcode
          { admin ? (
              contributorId ? ' στο λογαριασμό του χρήστη'
              : ( printRun ? ' στην ομάδα.' : ' χωρίς ομάδα.' )
            ) : ' στο λογαριασμό σου.'
          }
        </Message>
      </Segment>
    );
  }

  const allSelected = selectedSheets && sheets.data?.every(({ id }) => selectedSheets.includes(id) );
  const noneSelected = selectedSheets?.length === 0;

  const onRowSelection = (id, checked) => setSelected((previous) => checked ? [...previous, id] : previous.filter((i) => i !== id));
  const onToggleSelectAll = (checked) => setSelected(checked ? sheets.data?.map(({id}) => id) : []);

  // add new props going forward
  props = {
    admin,
    contributorId,
    sheets,
    selectedSheets,
    allSelected,
    noneSelected,
    onRowSelection,
    onToggleSelectAll,
    ...props
  };

  const paginationProps = {
    activePage: page,
    totalPages: sheets.data?.total_pages,
    disabled: sheets.data?.total_pages === 1,
    onPageChange: (e, { activePage }) => history.push(`${activePage}`),
  };

  // hide pagination of one page if not admin
  const showPagination = admin || sheets.data?.total_pages > 1;

  return (<>
    { (contributorId || !admin) &&
      <Header as="h3" textAlign="center" content={ admin ? 'Ενεργοποιημένοι κωδικοί barcode' : 'Οι barcode κωδικοί σου'} />
    }

    <Grid container columns={1}>
      <Grid.Column only="mobile">
        <Ref innerRef={contextRef}>
          <div style={{ textAlign: 'center' }}>
            { showPagination &&
              <Sticky context={contextRef}>
                <OrientedPagination {...paginationProps}
                  // override some props to be mobile friendly
                  boundaryRange={1} siblingRange={0}
                  size="large" pointing={false} secondary={false}
                />
              </Sticky>
            }
            <BarcodesTableMobile {...props} />
          </div>
        </Ref>
      </Grid.Column>
      <Grid.Column only="tablet computer">
        { showPagination && <OrientedPagination {...paginationProps} /> }
        <BarcodesTableComputer {...props} />
      </Grid.Column>
    </Grid>
  </>);
}


function BarcodesAvailableGrid({ sheets, itemsPerRow }) {
  const match = useRouteMatch();

  return (
    <Card.Group stackable itemsPerRow={itemsPerRow}>
      { sheets.data?.results.map(({ id_code }) => 
          <Card key={id_code}>
            <Card.Content textAlign="center">
              <Card.Header><Icon name="barcode" />{ id_code }</Card.Header>
            </Card.Content>
            <Card.Content extra textAlign="center">
              <Button fluid compact content="Ενεργοποίηση" as={Link} to={`${match.url}/activate/${id_code}`} />
            </Card.Content>
          </Card>
      ) }
    </Card.Group>
  )
}


export function BarcodesAvailable() {
  const contextRef = useRef();

  const match = useRouteMatch();
  const history = useHistory();

  const params = useParams();
  const { runId: printRun, page } = params;

  // get the url without the paging params
  const baseUrl = generatePath(match.path, omit(params, ['page', 'pageKey']));
  const sheets = useAvailableBarcodes(
    // this component is never mounted under a view where missing runId means any run id
    { printRun: printRun || 'none', page, pageSize: 15 },
    { onPageInvalid: () => history.replace(baseUrl) }
  );

  if (!sheets.isPreviousData && sheets.data?.total_pages > 1 && !page) {
    return <Redirect to={`${baseUrl}/page/1`} />
  }

  if (sheets.isLoading) {
    return <Segment basic placeholder loading />
  }
  if (sheets.isError) {
    return <Message error content="Οι πληροφορίες για διαθέσιμους κωδικούς barcode δεν είναι διαθέσιμες αυτή τη στιγμή." />;
  }

  if ( sheets.data?.count === 0 ) {
    return (
      <Segment basic textAlign="center" size="large">
        <Message compact>
          Δεν υπάρχουν διαθέσιμοι προς ενεργοποίηση κωδικοί barcode
          { printRun ? ' στην ' : ' χωρίς ' }
          ομάδα.
        </Message>
      </Segment>
    );
  }

  const paginationProps = {
    activePage: page,
    totalPages: sheets.data?.total_pages,
    disabled: sheets.data?.total_pages === 1,
    onPageChange: (e, { activePage }) => history.push(`${activePage}`),
  };

  return (
    <>
      <Grid container columns={1}>
        <Grid.Column only="mobile">
          <Ref innerRef={contextRef}>
            <div style={{ textAlign: 'center' }}>
              <Sticky context={contextRef}>
                <OrientedPagination {...paginationProps}
                  // override some props to be mobile friendly
                  boundaryRange={1} siblingRange={0}
                  size="large" pointing={false} secondary={false}
                />
              </Sticky>
              <BarcodesAvailableGrid sheets={sheets} itemsPerRow={1} />
            </div>
          </Ref>
        </Grid.Column>
        <Grid.Column only="tablet computer">
          <OrientedPagination {...paginationProps} />
          <BarcodesAvailableGrid sheets={sheets} itemsPerRow={3} />
        </Grid.Column>
      </Grid>

      <Route path={`${match.path}/activate/:code`}>
        <Modal open size="tiny" dimmer="inverted" onClose={() => history.replace(match.url)}>
          <Modal.Header>Ενεργοποίηση barcode</Modal.Header>
          <Modal.Content>
            <BarcodeAssignment.Form admin />
          </Modal.Content>
          <Modal.Actions>
            <Button content="Επιστροφή" as={Link} to={match.url} replace />
          </Modal.Actions>
        </Modal>
      </Route>
    </>
  );
}
