import { Fragment, useEffect } from 'react';
import { useQuery } from 'react-query';
import { Button, Container, Divider, Icon, Image, Segment } from 'semantic-ui-react';

import { CircleMarker, MapContainer, Popup, useMap } from 'react-leaflet';
import GoogleLayer from 'react-leaflet-google-layer';

import binImg from '../images/cookingoils-bin.png';
import atmImg from '../images/atm.png';

// needed for embedded map stuff
import 'leaflet-gesture-handling';
import 'leaflet-gesture-handling/dist/leaflet-gesture-handling.css';
import 'leaflet/dist/leaflet.css';

import { isEmpty } from 'lodash';

import L from 'leaflet';
import axios from 'axios';
import qs from 'qs';


const binCode = 6;
const atmCode = 11;
const dumpsterCategories = [binCode, atmCode];

function MapBoundsFit({ bounds, maxZoom=15 }) {
  const map = useMap();

  useEffect(() => {
    if (!isEmpty(bounds)) {
      map.fitBounds(bounds, { padding: [50, 50], maxZoom });
    }
  }, [map, bounds, maxZoom]);

  return null;
}

export default function WasteOilMap() {
  const dumpsters = useQuery('dumpsters',
    () => axios.get(
      process.env.REACT_APP_DUMPSTERS_URL + '/api/dumpsters/?' + qs.stringify({
        type: dumpsterCategories,
        match: 'any'
      }, { arrayFormat: 'repeat' }),
      { crossDomain: true }
    ).then(
      ({data}) => ({
        points: data, bounds: L.latLngBounds(data.map(({lat, lon}) => [lat, lon]))
      })
    ), {
      staleTime: Infinity
    }
  );

  return (
      <MapContainer className="bins-map" center={[40.73, 21.40]} zoom={11} scrollWheelZoom={false} gestureHandling>
        <MapBoundsFit bounds={dumpsters.data?.bounds} />
        <GoogleLayer apiKey={process.env.REACT_APP_GOOGLE_API_KEY} language="el" type="roadmap" />
        { dumpsters.data?.points.map(
            ({ id, lat, lon, categories }) => {
              categories = categories.filter(({code}) => dumpsterCategories.includes(code));

              const defaultColor = categories[0]?.color || 'rebeccablue';
              return (
                <CircleMarker key={id} center={{lat, lon}} color={defaultColor} fillColor={defaultColor} fillOpacity={1} radius={10}>
                  <Popup minWidth={320} closeButton={false}>
                    <Segment basic>
                      { categories.map(({code, name, color}, index) =>
                          <Fragment key={code}>
                            { index > 0 && <Divider /> }
                            { dumpsterCategories.includes(code) ?
                                <Image size="mini" inline spaced src={code === binCode ? binImg : atmImg} />
                              : <Image size="mini" inline spaced>
                                  <Container textAlign="center">
                                    <Icon size="big" name="circle" style={{ color }} />
                                  </Container>
                                </Image>
                            }
                            <strong>{name}</strong>
                          </Fragment>
                        )
                      }

                      <Divider />

                      <Button fluid icon="google" primary
                        content="Δες οδηγίες μετάβασης"
                        as="a" href={'https://www.google.com/maps/dir/?api=1&' + qs.stringify({
                          destination: `${lat},${lon}`,
                        })} target="_blank" rel="noreferrer"
                      />
                      <Divider fitted hidden />
                      <Button fluid icon="external"
                        content="Δες περισσότερα για το σημείο"
                        as="a" href={`${process.env.REACT_APP_DUMPSTERS_URL}/${id}`} target="_blank" rel="noreferrer"
                      />
                    </Segment>
                  </Popup>
                </CircleMarker>
              );
            }
          )
        }
      </MapContainer>
  );
}
