import { useQuery } from 'react-query';

import axios from 'axios';


const useBarcodes = (queryName, { printRun, contributor, available, page, pageSize=10 }, { onPageInvalid, onError, ...options }={}) =>
  useQuery(
    [queryName, { printRun, contributor, available, page, pageSize }],
    () => axios.get('/api/sheets/', {
      params: {
        contributor: contributor || undefined,
        print_run: printRun,
        is_registered: available === undefined ? undefined : !available,
        page,
        page_size: pageSize || undefined,
        ordering: '-registered_at',
      }
    }).then(({ data }) => data),
    {
      keepPreviousData: true,
      retry: (failureCount, error) => {
        // don't retry on invalid page errors
        if (error.response.data?.detail === 'Invalid page.') return false;
        return failureCount < 2; // default behaviour
      },
      onError: onPageInvalid && ((error) => {
        if (error.response.data?.detail === 'Invalid page.') {
          onPageInvalid();
        } else if (onError) {
          onError(error);
        } else {
          throw error;
        }
      }),
      ...options,
    }
);

export const useRegisteredBarcodes = ({ printRun, contributor, page, pageSize }, options) => 
    useBarcodes('registeredBarcodes', { printRun, contributor, available: false, page, pageSize }, options);

export const useAvailableBarcodes = ({ printRun, page, pageSize }, options) =>
    useBarcodes('availableBarcodes', { printRun, available: true, page, pageSize }, options);
