import { useQuery } from 'react-query';

import axios from 'axios';


export const useContributor = (contributor, options) =>
  useQuery(['contributor', contributor],
    () => contributor && axios.get(`/api/contributors/${contributor}/`).then(({data}) => data),
    options
  )
;

export const useContributors = (params, options, queryName) =>
  useQuery([queryName || 'contributors', params],
    () => axios.get('/api/contributors/', { params }).then(({data}) => data),
    { keepPreviousData: true, ...options}
  )
;
