import { useEffect, useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { Helmet } from 'react-helmet';

import { Table, Button, Input, Header, Container, Segment, Divider } from 'semantic-ui-react';

import axios from 'axios';


function CollectionsSettings() {
  const { data: containers } = useQuery('containers',
    () => axios.get('/api/containers/').then(({data}) => data),
    { initialData: [] }
  );

  const mutation = useMutation(() => axios.post('/api/containers/points/', containerPoints),
    { onSuccess: () => setTimeout(mutation.reset, 1000) }
  );

  const [containerPoints, setContainerPoints] = useState(() => ({}));

  // re-init state on containers data availability
  useEffect(() => {
    setContainerPoints(
      Object.fromEntries(containers.map(({id, points}) => [id, points]))
    );
  }, [containers]);

  const handlePointsChange = (e, {name, value}) => {
    setContainerPoints((p) => ({...p, [name]: value}));
  };

  const pageTitle = 'Ρυθμίσεις απόδοσης πόντων';
  return (<>
    <Container>
      <Helmet title={pageTitle} />
      <Header as="h1" textAlign="center" className="colored atmgreen" content={pageTitle} />
      <Divider className="orange" />
      <Divider hidden />
    </Container>

    <Container text>
      <Segment basic clearing>
        <Header as="h4">Εδώ μπορείτε να ρυθμίσετε πόσοι πόντοι αποδίδονται με την παράδοση μπουκαλιών ανά τύπο και χωρητικότητά τους.</Header>
        <Table compact fixed>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell textAlign="center">Τύπος μπουκαλιού</Table.HeaderCell>
              <Table.HeaderCell>Πόντοι</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            { containers.map(({ id, label }) => 
                <Table.Row key={id}>
                  <Table.Cell>{label}</Table.Cell>
                  <Table.Cell><Input type="number" input={{ min: 0 }} fluid value={containerPoints[id] || ''} name={id} onChange={handlePointsChange} /></Table.Cell>
                </Table.Row>
              )
            }
          </Table.Body>
        </Table>

        <Button floated="right" primary
          icon={mutation.isSuccess ? 'check' : undefined}
          content={ mutation.isSuccess ? undefined : 'Αποθήκευση'}
          onClick={() => mutation.mutate()}
          toggle active={mutation.isSuccess}
        />
      </Segment>
      
      <Divider hidden />
    </Container>
  </>);
}

export default CollectionsSettings;
