import { useState } from 'react';
import { Message, Input, Form, List, Button } from 'semantic-ui-react';

import { useContributors } from '../queries/contributors';


function ContributorSearch({ onSelect }) {
  const [search, setSearch] = useState('');
  const [selectedContributor, setSelectedContributor] = useState();

  const contributors = useContributors({ search }, {  enabled: false }, 'contributor_search');

  const handleSearch = () => {
    if (!search) return;
    contributors.refetch();
  };

  const handleDismiss = () => contributors.remove();

  return (
    <Form size="large" onSubmit={handleSearch} error={contributors.error} warning={contributors.data?.length === 0}>
      <Input icon="mail" iconPosition="left" fluid size="large"
        value={search}
        onChange={(e, {value}) => setSearch(value)}
        action={{
          primary: true,
          disabled: !search,
          content: 'Αναζήτηση',
        }}
      />

      <Message error header="Σφάλμα κατά την αναζήτηση χρηστών." onDismiss={handleDismiss} />
      <Message warning content="Δεν εντοπίστηκαν εγγεγραμμένοι χρήστες με τα στοιχεία που δώσατε." />

      { contributors.data?.length > 0 && <>
        <Message info content="Επιλέξτε τον χρήστη για τον οποίον θέλετε να ενεργοποιηθεί ο κωδικός." />

        <List selection divided relaxed style={{ maxHeight: '30vh', overflowY: 'auto' }}>
          { contributors.data?.map(({id, full_name, email}) =>
              <List.Item key={id} onClick={selectedContributor !== id ? () => setSelectedContributor(id) : undefined}>
                { selectedContributor === id && 
                  <List.Content floated="right">
                    <Button type="button" positive content="Ενεργοποίηση" onClick={() => onSelect({ contributor: id, email })}/>
                  </List.Content>
                }
                <List.Content>
                  <List.Header>{ full_name || 'Άγνωστο όνομα' }</List.Header>
                  <List.Description>{ email }</List.Description>
                </List.Content>
              </List.Item>
            )
          }
        </List>
      </> }

    </Form>
  );
}

export default ContributorSearch;
