import { useQuery } from 'react-query';

import axios from 'axios';


export const useCollections = ({ contributor, code, reverted, page, pageSize=10 }, { onPageInvalid, onError, ...options }) =>
  useQuery(
    ['collections', { contributor, code, reverted, page, pageSize }],
    () => axios.get('/api/collections/', {
      params: {
        contributor,
        code,
        reverted: reverted === false ? false : undefined,
        page,
        page_size: pageSize,
        ordering: '-collected_at',
      }
    }).then(({data}) => data),
    {
      keepPreviousData: true,
      retry: (failureCount, error) => {
        // don't retry on invalid page errors
        if (error.response.data?.detail === 'Invalid page.') return false;
        return failureCount < 2; // default behaviour
      },
      onError: onPageInvalid && ((error) => {
        if (error.response.data?.detail === 'Invalid page.') {
          onPageInvalid();
        } else if (onError) {
          onError(error);
        } else {
          throw error;
        }
      }),
      ...options,
    }
);

export const useCollectionsExport = (
  { contributor, code, reverted },
  options
) => {
  const params = { contributor, code, reverted };
  return useQuery(
    ['collectionsExport', { params }],
    () =>
      axios
        .get('/api/collections/export/', { params, responseType: 'blob' })
        .then(({ data }) => data),
    {
      enabled: false,
      staleTime: 0,
      cacheTime: 0,
      ...options,
    }
  );
};
