import { useEffect } from 'react';
import { Route, Switch, Redirect, useLocation } from 'react-router-dom';

import { Loader } from 'semantic-ui-react';

import { AnnouncementsAdmin } from '@zerowaste/components';

import CollectionsAdmin from './CollectionsAdmin';
import { CollectionsHistoryAdmin } from './CollectionsHistory';
import BarcodesAdmin from './BarcodesAdmin';
import ContributorsAdmin from './ContributorsAdmin';
import CollectionsSettings from './CollectionsSettings';
import WasteOilDashboard from './WasteOilDashboard';

import useProfile from '../queries/useProfile';

import qs from 'qs';
import { Helmet } from 'react-helmet';


export default function WasteOilAdmin({ onLogin }) {
  const location = useLocation();
  const profile = useProfile();

  const { cancelTo, ...params } = qs.parse(location.search, { ignoreQueryPrefix: true });

  useEffect(() => {
    if (profile.isFetched && !profile.data) {
      // if we are logged out and want to go to admin, let's redirect them to login page
      onLogin?.(location.pathname + `?${qs.stringify(params)}`, '/')();
    }
  }, [profile, location, params, onLogin])

  // wait for it...
  if (!profile.isFetched) {
    return <Loader inline="centered" size="massive" active />;
  }

  if (profile.data?.contributor) {
    // we are a contributor, just take them to the root
    return <Redirect to="/" replace />;
  }

  if (!profile.data) {
    if (onLogin) {
      // if logged out, useEffect will do the rest
      return null;
    } else {
      // useEffect won't do anything, fallback to local login screen
      return <Redirect to={{
        pathname: '/login',
        search: `?${qs.stringify({
          returnTo: location.pathname + `?${qs.stringify(params)}`,
          cancelTo,
        })}`
      }} />;
    }
  }

  // we are NOT a contributor, so we assume we are an employee
  const isSupervisor = profile.data?.is_supervisor;

  return profile.isFetched ?
    <Switch>
      <Route path="/admin" exact>
        <Redirect to={isSupervisor ? '/admin/dashboard' : '/admin/collect'} />
      </Route>

      <Route path="/admin/collect">
        <CollectionsAdmin />
      </Route>

      <Route path="/admin/collections">
        <CollectionsHistoryAdmin />
      </Route>

      { isSupervisor &&
        <>
          <Route key="dashboard" path="/admin/dashboard" >
            <WasteOilDashboard />
          </Route>
          <Route key="contributors" path="/admin/contributors">
            <ContributorsAdmin />
          </Route>
          <Route key="barcodes" path="/admin/barcodes">
            <BarcodesAdmin />
          </Route>
          <Route key="settings" path="/admin/settings">
            <CollectionsSettings />
          </Route>
          <Route key="news" path="/admin/news">
            <Helmet defaultTitle="Διαχείριση αναρτήσεων | Πλατφόρμα ανακύκλωσης τηγανέλαιων &ndash; ΔΙΑΔΥΜΑ ΑΕ"
              titleTemplate="%s | Διαχείριση αναρτήσεων | Πλατφόρμα ανακύκλωσης τηγανέλαιων &ndash; ΔΙΑΔΥΜΑ ΑΕ"
            />
            <AnnouncementsAdmin />
          </Route>
        </>
      }

      <Route path="*">
        <Redirect to="/admin" />
      </Route>

    </Switch>

    : <Loader inline="centered" size="massive" active />
  ;
}
