import { Fragment, useRef, useState } from 'react';
import { Link, Route, Redirect, useParams, useRouteMatch, useLocation, useHistory, generatePath } from 'react-router-dom';
import { useMutation, useQueryClient } from 'react-query';
import { Helmet } from 'react-helmet';

import { Container, Message, Table, Header, Form, Icon, Grid, Divider, Button, Segment, Sticky, Ref } from 'semantic-ui-react';

import { ConfirmPopup, OrientedPagination } from '@zerowaste/components';

import ContributorsList from './ContributorsList';

import useProfile from '../queries/useProfile';
import { useCollections, useCollectionsExport } from '../queries/collections';

import axios from 'axios';
import qs from 'qs';
import { omit } from 'lodash';


function CodeSearchForm({ value, onSearch, mobile, loading }) {
  const [barcode, setBarcode] = useState(value || '');
  const handleClear = () => {
    setBarcode('');
    onSearch('');
  };

  return (
    <Form onSubmit={() => onSearch(barcode)}>
      <Form.Group grouped={mobile} inline={!mobile}>
        <Form.Input icon={ barcode ? <Icon name="delete" link onClick={handleClear} /> : "search"}
          value={barcode} onChange={(e, {value}) => setBarcode(value)}
        />
        <Form.Button fluid={mobile} primary content="Αναζήτηση barcode" loading={barcode ? loading : false} />
      </Form.Group>
    </Form>
  );
}


// renders a cancel component for each collection event
function CancelScanButton({ position, button, cancelScan, event: { id, is_final }}) {
  const isLoading = cancelScan.variables === id && cancelScan.isLoading;
  const isError = cancelScan.variables === id && cancelScan.isError;

  const trigger = button ? 
    <Button icon="cancel" negative compact
      content="Ακύρωση σάρωσης"
      title={ is_final ? 'Δεν επιτρέπεται η ακύρωση' : undefined }
      disabled={is_final}
      loading={isLoading}
    />
    : <Icon name="times circle" color="red" size="large"
      title={is_final ? 'Δεν επιτρέπεται η ακύρωση' : 'Ακύρωση σάρωσης'}
      disabled={is_final}
      link={!is_final}
      loading={isLoading}
    />
  ;

  return (
    <ConfirmPopup position={position} trigger={trigger}
      header="Θέλετε να ακυρωθεί η σάρωση αυτή;"
      onConfirm={() => cancelScan.mutate(id)}
      error={isError && 'Δεν ήταν δυνατή η ακύρωση της σάρωσης.' }
      onDismissError={() => cancelScan.reset()}
    />
  );
}


function CollectionsTableComputer({ baseUrl, isStaff, isSupervisor, isContributor, contributorId, collections, cancelScan }) {
  const cancelColumn = isStaff; //&& collections.data?.count > 0;

  let tableCols = 4;
  if (cancelColumn) tableCols += 1;
  if (isSupervisor) {
    tableCols += 2;
    // "Take back one kadam to honor the Hebrew God, whose Ark this is."
    // ―Inscription on the Headpiece, as translated by Imam
    if (contributorId) tableCols -= 1;
  };

  return (
    <Table celled selectable>
      <Table.Header>
      <Table.Row>
        { isSupervisor && !contributorId && <Table.HeaderCell>Χρήστης</Table.HeaderCell> }
        <Table.HeaderCell>Barcode</Table.HeaderCell>
        <Table.HeaderCell>Ημερομηνία συλλογής</Table.HeaderCell>
        { isSupervisor && <Table.HeaderCell>Χειριστής</Table.HeaderCell> }
        <Table.HeaderCell>Τύπος μπουκαλιού</Table.HeaderCell>
        <Table.HeaderCell>Πόντοι</Table.HeaderCell>
        { cancelColumn && <Table.HeaderCell>
          {/* to keep the column width static */}
          <Icon name="times circle" size="large" style={{ visibility: 'hidden' }} />
        </Table.HeaderCell> }
      </Table.Row>
      </Table.Header>
      <Table.Body>
        { collections.data?.results.map(({id, is_reverted, is_final, collected_at, contributor, batch_id_code, container: { label: container }, points, collected_by }) =>
            <Table.Row key={id} negative={is_reverted} warning={!is_final}>
              { isSupervisor && !contributorId && <Table.Cell><Link to={`/admin/contributors/${contributor.id}`}>{contributor.full_name || 'Άγνωστο όνομα'}</Link></Table.Cell> }
              <Table.Cell>{batch_id_code}</Table.Cell>
              <Table.Cell>{new Date(collected_at).toLocaleString('el')}</Table.Cell>
              { isSupervisor && <Table.Cell>{collected_by?.full_name}</Table.Cell> }
              <Table.Cell>{container}</Table.Cell>
              <Table.Cell style={{ textDecoration: is_reverted ? 'line-through' : undefined }}>{points}</Table.Cell>
              { cancelColumn &&
                <Table.Cell collapsing>
                  { !is_final && <CancelScanButton position="right center" cancelScan={cancelScan} event={{ id, is_final }} />}
                </Table.Cell>
              }
            </Table.Row>
          )
        }
        { collections.data?.count === 0 &&
          <Table.Row textAlign="center">
            <Table.Cell colSpan={tableCols}>
              Δεν εντοπίστηκαν στοιχεία συλλογής μπουκαλιών
              { isContributor ? '.' : ' που ικανοποιούν τα φίλτρα που έχετε επιλέξει.' }
              { isStaff &&
                  <>
                    <Divider hidden fitted />
                    <Link to={baseUrl}>Επαναφορά φίλτρων</Link>
                  </>
              }
            </Table.Cell>
          </Table.Row>
        }
      </Table.Body>
    </Table>
  );
}


function CollectionsTableMobile({ baseUrl, isStaff, isSupervisor, isContributor, contributorId, collections, cancelScan }) {
  return (<div>
    <Table unstackable definition fixed>
      <Table.Body>
        { collections.data?.results.map(({id, is_reverted, is_final, collected_at, contributor, batch_id_code, container: { label: container }, points, collected_by }, index) =>
          <Fragment key={id}>
            { index > 0 && 
              <Table.Row><Table.Cell style={{ backgroundColor: 'white' }} colSpan={2} /></Table.Row>
            }

            { isSupervisor && !contributorId &&
              <Table.Row negative={is_reverted}>
                <Table.Cell>Χρήστης</Table.Cell>
                <Table.Cell><Link to={`/admin/contributors/${contributor.id}`}>{contributor.full_name || 'Άγνωστο όνομα'}</Link></Table.Cell> 
              </Table.Row>
            }

            <Table.Row negative={is_reverted}>
              <Table.Cell>Barcode</Table.Cell>
              <Table.Cell>{batch_id_code}</Table.Cell>
            </Table.Row>

            <Table.Row negative={is_reverted}>
              <Table.Cell>Ημερομηνία συλλογής</Table.Cell>
              <Table.Cell>{new Date(collected_at).toLocaleString('el')}</Table.Cell>
            </Table.Row>

            { isSupervisor &&
              <Table.Row negative={is_reverted}>
                <Table.Cell>Χειριστής</Table.Cell>
                <Table.Cell>{collected_by?.full_name}</Table.Cell>
              </Table.Row>
            }

            <Table.Row negative={is_reverted}>
              <Table.Cell>Τύπος μπουκαλιού</Table.Cell>
              <Table.Cell>{container}</Table.Cell>
            </Table.Row>

            <Table.Row negative={is_reverted}>
              <Table.Cell>Πόντοι</Table.Cell>
              <Table.Cell style={{ textDecoration: is_reverted ? 'line-through' : undefined }}>{points}</Table.Cell>
            </Table.Row>

            { isStaff && !is_final &&
              <Table.Row>
                <Table.Cell textAlign="center" style={{ backgroundColor: 'white' }} colSpan={2}>
                  <CancelScanButton button position="bottom left" cancelScan={cancelScan} event={{ id, is_final }} />
                </Table.Cell>
              </Table.Row>
            }

          </Fragment>
        ) }

        { collections.data?.count === 0 &&
          <Table.Row textAlign="center">
            <Table.Cell colSpan={2}>
              Δεν εντοπίστηκαν στοιχεία συλλογής μπουκαλιών
              { isContributor ? '.' : ' που ικανοποιούν τα φίλτρα που έχετε επιλέξει.' }
              { isStaff &&
                  <>
                    <Divider hidden fitted />
                    <Link to={baseUrl}>Επαναφορά φίλτρων</Link>
                  </>
              }
            </Table.Cell>
          </Table.Row>
        }

      </Table.Body>
    </Table>
  </div>);
}


function CollectionsTable({ collections, ...props }) {
  const contextRef = useRef();

  const history = useHistory();
  const { search } = useLocation();

  const params = useParams();
  const { contributorId, page } = params;

  const queryClient = useQueryClient();
  const cancelScan = useMutation(
    (eventId) => axios.post(`/api/collections/${eventId}/cancel/`),
    { onSuccess: () => queryClient.invalidateQueries('collections') }
  );

  if (collections.isLoading) {
    return <Segment basic placeholder loading />
  }
  if (collections.isError) {
    return <Message error content="Οι πληροφορίες ιστορικού συλλογής δεν είναι διαθέσιμες αυτή τη στιγμή." />;
  }

  const paginationProps = {
    activePage: page,
    totalPages: collections.data?.total_pages,
    onPageChange: (e, { activePage }) => history.push({ pathname: `${activePage}`, search }),
    disabled: collections.data?.total_pages === 1,
  };

  // hide pagination of one page if not admin
  const showPagination = !props.isContributor || collections.data?.total_pages > 1;
  return (
    <Grid columns={1}>
      <Grid.Column only="mobile">
        <Ref innerRef={contextRef}>
          <div style={{ textAlign: 'center' }}>
            { showPagination &&
              <Sticky context={contextRef}>
                <OrientedPagination {...paginationProps}
                  boundaryRange={1} siblingRange={0}
                  size="large" pointing={false} secondary={false}
                />
              </Sticky>
            }
            <CollectionsTableMobile cancelScan={cancelScan} collections={collections} contributorId={contributorId} {...props} />
          </div>
        </Ref>
      </Grid.Column>
      <Grid.Column only="tablet computer">
        { showPagination && <OrientedPagination {...paginationProps} /> }
        <CollectionsTableComputer cancelScan={cancelScan} collections={collections} contributorId={contributorId} {...props} />
      </Grid.Column>
    </Grid>
  );
}


export default function CollectionsHistory() {
  const history = useHistory();
  const match = useRouteMatch();
  const params = useParams();
  const { contributorId, page } = params;

  const { search } = useLocation();
  const filter = qs.parse(search, { ignoreQueryPrefix: true });

  // pick up auth stuff
  const profile = useProfile();
  const isStaff = profile.data?.is_employee || profile.data?.is_supervisor;
  const isSupervisor = profile.data?.is_supervisor;
  const isContributor = !!profile.data?.contributor;

  const { barcode: codeFilter='' } = filter;

  // get the base path without the paging params
  const baseUrl = generatePath(match.path, omit(params, ['page', 'pageKey']));

  const collections = useCollections(
    { contributor: contributorId, code: codeFilter, page },
    // go back to page index
    { onPageInvalid: () => history.replace({ pathname: baseUrl, search }) }
  );

  const handleCodeSearch = (value) => history.push({
    search: '?' + qs.stringify({
      ...filter,
      barcode: value || undefined,
    })
  });

  const exportExcel = useCollectionsExport({ contributor: contributorId });
  const handleExportData = () => {
    exportExcel.refetch().then((response) => {
      const file = new File(
        [response.data],
        'Ιστορικό συλλογής τηγανέλαιων.xlsx',
        {
          type: response.data.type,
        }
      );
      const href = URL.createObjectURL(file);
      window.location = href;
      URL.revokeObjectURL(href);
    });
  };

  if (!collections.isPreviousData && collections.data?.total_pages > 1 && !page) {
    return <Redirect to={{ pathname: `${baseUrl}/page/1`, search }} />
  }

  return (<>
    { isContributor && 
      <Container>
        <Header as="h1" textAlign="center" className="colored atmgreen">Ιστορικό παράδοσης μπουκαλιών</Header>
        <Divider className="orange" />
        <Divider hidden />
      </Container>
    }

    <Container text={isContributor}>
      { !isContributor &&
        <Grid>
          <Grid.Row>
            <Grid.Column width={10} only="computer tablet">
              <CodeSearchForm key={codeFilter} value={codeFilter} onSearch={handleCodeSearch} loading={collections.isLoading} />
            </Grid.Column>
            <Grid.Column width={16} only="mobile">
              <CodeSearchForm key={codeFilter} value={codeFilter} onSearch={handleCodeSearch} loading={collections.isLoading} mobile />
            </Grid.Column>
            <Grid.Column computer={6} tablet={6} mobile={16} textAlign="right">
              <Button content="Εξαγωγή σε Excel" icon="download"
                onClick={handleExportData}
                loading={exportExcel.isFetching}
                disabled={exportExcel.isFetching}
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      }

      <CollectionsTable
        isStaff={isStaff} isSupervisor={isSupervisor} isContributor={isContributor} contributorId={contributorId}
        collections={collections}
      />

      { isContributor && <Divider hidden /> }

    </Container>
  </>);
}


export function CollectionsHistoryAdmin() {
  const match = useRouteMatch();

  const profile = useProfile();
  const supervisor = profile.data?.is_supervisor;

  const pageTitle = 'Ιστορικό συλλογής τηγανέλαιων';
  return (
    <Container>
      <Helmet title={pageTitle} />
      <Header as="h1" textAlign="center" className="colored atmgreen" content={pageTitle} />
      <Divider className="orange" />
      <Divider section hidden />

      <Grid>
        <Grid.Row>
          { supervisor && 
            <Grid.Column width={4}>
              <ContributorsList parentPageTitle={pageTitle} parentMatch={match} filter as="div" listHeight="50vh" />
            </Grid.Column>
          }

          <Grid.Column width={supervisor ? 12 : undefined}>

            <Route path={`${match.path}/:contributorId(\\d+)?/:pageKey(page)?/:page(\\d+)?`}>
              <CollectionsHistory />
            </Route>

          </Grid.Column>
        </Grid.Row>
      </Grid>

      <Divider hidden />

    </Container>
  );
}
